import React from 'react';
import { connect } from 'react-redux';
import { Form, Card, Row, Col, Icon, notification, Button,Modal,Spin } from 'antd';
import { get, post, ppost } from '../../../utils';
import ImageUpload from '../../../containers/ImageUpload';
import VideoUpload from '../../../containers/VideoUpload';
import config from '../../../config.json'
import './style.scss'
import { Link } from 'react-router-dom'
import swiperHomePage from '../../../resource/swiper/swiper-home-page.png'
import swiperGetStartedPage from '../../../resource/swiper/swiper-get-started-page.png'
const { Meta } = Card;

const languageMap = [
    {
        id: 1,
        code: "EN",
        variable: "_en",
    },
    {
        id: 2,
        code: "TH",
        variable: "",
    }
]

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class Swiper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            swiperHomePageList: [],
            swiperGetStartedPageList: [],
            swiperBannerList: [],
            headerBackground: [],
            swiperThumbnailVideoList: [],
            swiperVideoList: [],

            swiperHomePageList_en: [],
            swiperGetStartedPageList_en: [],
            swiperBannerList_en: [],
            headerBackground_en: [],
            swiperThumbnailVideoList_en: [],
            swiperVideoList_en: [],
        }
    }


    async setEditData(responses) {
        console.log('responsesresponsesresponses',responses)
        const { languageList } = this.props
        
        languageList.map(async (language) => {
            const matchedLanguage = languageMap.find(lm => lm.id === language.id);
            const { variable } = matchedLanguage;
            const swiperHomePageListKey = `swiperHomePageList${variable}`;
            const swiperGetStartedPageListKey = `swiperGetStartedPageList${variable}`;
            const swiperBannerListKey = `swiperBannerList${variable}`;
            const swiperVideoListKey = `swiperVideoList${variable}`;
            const swiperThumbnailVideoListKey = `swiperThumbnailVideoList${variable}`;
            const headerBackgroundKey = `headerBackground${variable}`;
            let swiperHomePageList = await this.filterList(responses,'HOME_PAGE', language.id)
            let swiperGetStartedPageList = await this.filterList(responses,'GET_STARTED_PAGE', language.id)
            let swiperBannerList = await this.filterList(responses,'BANNER_HOME_PAGE', language.id)
            let swiperVideoList = await this.filterList(responses,'VIDEO_HOME_PAGE', language.id)
            let swiperThumbnailVideoList = await this.filterList(responses,'THUMBNAIL_VIDEO_HOME_PAGE', language.id)
            let headerBackground = await this.filterList(responses,'HEADER_BACKGROUND_IMAGE', language.id)
            this.setState({
                [swiperHomePageListKey]: swiperHomePageList,
                [swiperGetStartedPageListKey]: swiperGetStartedPageList,
                [swiperBannerListKey]: swiperBannerList,
                [swiperVideoListKey]: swiperVideoList,
                [swiperThumbnailVideoListKey]: swiperThumbnailVideoList,
                [headerBackgroundKey]: headerBackground,
            })
        })
      
    }

    filterList(responses, itemCode, languageId){
        const { folderName } = this.props
        let filterList = responses.filter(item => item.code == itemCode && item.language_id === languageId).map(item => {
            let temp = Object.assign({}, item)
            temp['view'] = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.name}`
            temp['file'] = null
            return temp
        })
        return filterList
    }

    getSwiperList() {
        let url = '/api/crm/appearance/swiper/administrator';
        get(url)
            .then(responses => {
                this.setEditData(responses)
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getSwiperList()
    }

    async uploadImageToServer(formData) {
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    async updateSwiper() {
        this.setState({ loading: true });
    
        let uploadDataHomePageListMerge = [];
        let uploadDataGetStartedPageListMerge = [];
        let uploadDataThumbnailVideoHomePageListMerge = [];
        let uploadDataVideoHomePageListMerge = [];
        let uploadDataBannerHomePageListMerge = [];
        let uploadDataHeaderBackgroundHomeListMerge = [];
    
        try {
            for (const language of this.props.languageList) {
                const matchedLanguage = languageMap.find(lm => lm.id === language.id);
                if (!matchedLanguage) {
                    throw new Error(`No matching language found for ID: ${language.id}`);
                }
                const { variable } = matchedLanguage;
    
                const stateKeys = {
                    homePageList: `swiperHomePageList${variable}`,
                    getStartedPageList: `swiperGetStartedPageList${variable}`,
                    thumbnailVideoHomePageList: `swiperThumbnailVideoList${variable}`,
                    videoHomePageList: `swiperVideoList${variable}`,
                    bannerHomePageList: `swiperBannerList${variable}`,
                    headerBackground: `headerBackground${variable}`,
                };

                const [
                    uploadDataHomePageList,
                    uploadDataGetStartedPageList,
                    uploadDataThumbnailVideoHomePageList,
                    uploadDataVideoHomePageList,
                    uploadDataBannerHomePageList,
                    uploadDataHeaderBackgroundHomeList
                ] = await Promise.all([
                    this.setUpdateDataAndUpload(this.state[stateKeys.homePageList], 'HOME_PAGE', "image", language.id),
                    this.setUpdateDataAndUpload(this.state[stateKeys.getStartedPageList], 'GET_STARTED_PAGE', "image", language.id),
                    this.setUpdateDataAndUpload(this.state[stateKeys.thumbnailVideoHomePageList], 'THUMBNAIL_VIDEO_HOME_PAGE', "image", language.id),
                    this.setUpdateDataAndUpload(this.state[stateKeys.videoHomePageList], 'VIDEO_HOME_PAGE', "video", language.id),
                    this.setUpdateDataAndUpload(this.state[stateKeys.bannerHomePageList], 'BANNER_HOME_PAGE', "image", language.id),
                    this.setUpdateDataAndUpload(this.state[stateKeys.headerBackground], 'HEADER_BACKGROUND_IMAGE', "image", language.id)
                ]);
    
                uploadDataHomePageListMerge.push(...uploadDataHomePageList);
                uploadDataGetStartedPageListMerge.push(...uploadDataGetStartedPageList);
                uploadDataThumbnailVideoHomePageListMerge.push(...uploadDataThumbnailVideoHomePageList);
                uploadDataVideoHomePageListMerge.push(...uploadDataVideoHomePageList);
                uploadDataBannerHomePageListMerge.push(...uploadDataBannerHomePageList);
                uploadDataHeaderBackgroundHomeListMerge.push(...uploadDataHeaderBackgroundHomeList);
            }
            const data = {
                swiperHomePageList: JSON.stringify(uploadDataHomePageListMerge),
                swiperGetStartedPageList: JSON.stringify(uploadDataGetStartedPageListMerge),
                swiperVideoList: JSON.stringify(uploadDataVideoHomePageListMerge),
                swiperBannerList: JSON.stringify(uploadDataBannerHomePageListMerge),
                swiperThumbnailVideoList: JSON.stringify(uploadDataThumbnailVideoHomePageListMerge),
                swiperHeaderBackgroundHomeList: JSON.stringify(uploadDataHeaderBackgroundHomeListMerge),
            };
    
            const response = await post('/api/crm/administrator/appearance/swiper', data);
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
    
            openNotificationWithIcon('success', 'System notification', 'Save swiper success.');
        } catch (err) {
            console.error(err.message);
            openNotificationWithIcon('error', 'System notification', `Error: ${err.message}`);
        } finally {
            this.setState({ loading: false });
        }
    }
    
    
    async setUpdateDataAndUpload(datasource,itemCode,type, languageId){
        let uploadDatasourceList = datasource.filter(item => item.file)
        let uploadDataDatasourceList = datasource.filter(item => !item.file)

        if (uploadDatasourceList.length) {
            let formData = new FormData();
            uploadDatasourceList.map((item, index) => formData.append(type + index, item.file))
            let fileNameList = await this.uploadImageToServer(formData)
            fileNameList.map(fileName => {
                uploadDataDatasourceList.push({
                    code: itemCode,
                    name: fileName,
                    language_id: languageId
                })
            })
        }

        return uploadDataDatasourceList
    }

    onClickOk() {
        this.updateSwiper()
    }

    onClickCancel() {
        this.getSwiperList()
    }

    getRoleEnabled() {
        const { loading } = this.state
        const { roleDetail } = this.props
        if(loading){
            return true
        }
        if (roleDetail) {
            let index = roleDetail.findIndex(item => item.key === 'swiper')
            if (index != -1) {
                return roleDetail[index].edit
            }
            return false
        }
        return false
    }

    renderModalUpload() {
        return(
            <Modal 
                title={null}
                footer={null}
                visible={this.state.loading} 
                closable={false}
            >
                <div style={{ textAlign:'center' }}>
                    <Spin />
                </div>
                <div style={{ textAlign:'center' }}>
                    กรุณารออัพโหลด...
                </div>
            </Modal>
        )
    }

    render() {
        const { swiperHomePageList, swiperGetStartedPageList,swiperVideoList,swiperBannerList,swiperThumbnailVideoList,headerBackground } = this.state
        console.log("STATE: ", this.state)
        return (
            <div className='swiper-page-wrapper' style={{ backgroundColor: '#ffffff', height: '90vh' }}>
                <Row className="header-card-swiper" >
                    <Col span={12} style={{ textAlign: 'left',cursor: 'pointer' }} >
                        <Link to={'/'}>
                            <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                            <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >Swiper</span>
                        </Link>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? '#ffffff' : '#BFBFBF',
                                color: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2,
                                backgroundColor: this.getRoleEnabled() ? 'rgb(72, 197, 235)' : '#BFBFBF',
                                color: this.getRoleEnabled() ? '#ffffff' : '#ffffff',
                                border: this.getRoleEnabled() ? '1px solid rgb(72, 197, 235)' : '#ffffff',
                            }}
                            disabled={!this.getRoleEnabled()}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                    </Col>
                </Row>
                <Card className='cardstyle'>
                    <Form>
                    <Row gutter={24} style={{ backgroundColor: '#FFFFFF', borderBottom: "1px solid #e8e8e8" }}>
                        {this.props.languageList.map((language) => {
                            const matchedLanguage = languageMap.find(lm => lm.id === language.id);
                            if (!matchedLanguage) {
                                console.warn(`No matching language found for id: ${language.id}`);
                                return null;
                            }
                            const { variable, code } = matchedLanguage;
                            const stateKey = `swiperGetStartedPageList${variable}`;

                            return (
                                <Col span={24} key={language.id}>
                                    <div className="layout-setting-item">
                                        <div className="layout-setting-item-label">
                                            <p style={{ margin: "8px", fontWeight: '700', fontSize: 20 }}>
                                                {`Upload image swiper get started page (${code})`}
                                            </p>
                                        </div>
                                        <ImageUpload
                                            className={'image-swiper-get-started-page'}
                                            label={'Upload image swiper get started page '}
                                            previewImage={swiperGetStartedPage}
                                            limit={10}
                                            dataSource={this.state[stateKey]}
                                            updateDataSource={(dataSource) => this.setState({ [stateKey]: dataSource })}
                                        />
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                    <Row gutter={24} style={{ backgroundColor: '#FFFFFF', borderBottom: "1px solid #e8e8e8" }}>
                        {this.props.languageList.map((language) => {
                            const matchedLanguage = languageMap.find(lm => lm.id === language.id);
                            if (!matchedLanguage) {
                                console.warn(`No matching language found for id: ${language.id}`);
                                return null;
                            }
                            const { variable, code } = matchedLanguage;
                            const stateKey = `swiperHomePageList${variable}`;

                            return (
                                <Col span={24} key={language.id}>
                                    <div className="layout-setting-item">
                                        <div className="layout-setting-item-label">
                                            <p style={{ margin: "8px", fontWeight: '700', fontSize: 20 }}>
                                                {`Upload image swiper home page (${code})`}
                                            </p>
                                        </div>
                                        <ImageUpload
                                            className={'image-swiper-home-page'}
                                            label={'Upload image swiper home page'}
                                            previewImage={swiperHomePage}
                                            limit={10}
                                            dataSource={this.state[stateKey]}
                                            updateDataSource={(dataSource) => this.setState({ [stateKey]: dataSource })}
                                        />
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>


                    <Row gutter={24} style={{ backgroundColor: '#FFFFFF', borderBottom: "1px solid #e8e8e8" }}>
                        {this.props.languageList.map((language) => {
                            const matchedLanguage = languageMap.find(lm => lm.id === language.id);
                            if (!matchedLanguage) {
                                console.warn(`No matching language found for id: ${language.id}`);
                                return null;
                            }
                            const { variable, code } = matchedLanguage;
                            const stateKey = `swiperBannerList${variable}`;

                            return (
                                <Col span={24} key={language.id}>
                                    <div className="layout-setting-item">
                                        <div className="layout-setting-item-label">
                                            <p style={{ margin: "8px", fontWeight: '700', fontSize: 20 }}>
                                                {`Upload image banner (${code})`}
                                            </p>
                                        </div>
                                        <ImageUpload
                                            className={'image-swiper-home-page'}
                                            label={'Upload image banner'}
                                            previewImage={swiperHomePage}
                                            limit={10}
                                            dataSource={this.state[stateKey]}
                                            updateDataSource={(dataSource) => this.setState({ [stateKey]: dataSource })}
                                        />
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>

                    <Row gutter={24} style={{ backgroundColor: '#FFFFFF', borderBottom: "1px solid #e8e8e8" }}>
                        {this.props.languageList.map((language) => {
                            const matchedLanguage = languageMap.find(lm => lm.id === language.id);
                            if (!matchedLanguage) {
                                console.warn(`No matching language found for id: ${language.id}`);
                                return null;
                            }
                            const { variable, code } = matchedLanguage;
                            const stateKey = `headerBackground${variable}`;

                            return (
                                <Col span={24} key={language.id}>
                                    <div className="layout-setting-item">
                                        <div className="layout-setting-item-label">
                                            <p style={{ margin: "8px", fontWeight: '700', fontSize: 20 }}>
                                                {`Upload background header (${code})`}
                                            </p>
                                        </div>
                                        <ImageUpload
                                            className={'image-swiper-home-page'}
                                            label={'Upload background header'}
                                            previewImage={swiperHomePage}
                                            limit={10}
                                            dataSource={this.state[stateKey]}
                                            updateDataSource={(dataSource) => this.setState({ [stateKey]: dataSource })}
                                        />
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                        {/* <Row gutter={24} style={{ backgroundColor: '#FDFDFD' }}>
                            <ImageUpload
                                className={'image-swiper-home-page'}
                                label={'Upload background header'}
                                previewImage={swiperHomePage}
                                limit={1}
                                dataSource={headerBackground}
                                updateDataSource={(dataSource) => this.setState({ headerBackground: dataSource })}
                            />
                        </Row> */}

                        
                    <Row gutter={24} style={{ backgroundColor: '#FFFFFF', borderBottom: "1px solid #e8e8e8" }}>
                        {this.props.languageList.map((language) => {
                            const matchedLanguage = languageMap.find(lm => lm.id === language.id);
                            if (!matchedLanguage) {
                                console.warn(`No matching language found for id: ${language.id}`);
                                return null;
                            }
                            const { variable, code } = matchedLanguage;
                            const stateKey = `swiperThumbnailVideoList${variable}`;

                            return (
                                <Col span={24} key={language.id}>
                                    <div className="layout-setting-item">
                                        <div className="layout-setting-item-label">
                                            <p style={{ margin: "8px", fontWeight: '700', fontSize: 20 }}>
                                                {`Upload thumbnail video (${code})`}
                                            </p>
                                        </div>
                                        <ImageUpload
                                            className={'image-swiper-home-page'}
                                            label={'Upload thumbnail video'}
                                            previewImage={swiperHomePage}
                                            limit={10}
                                            dataSource={this.state[stateKey]}
                                            updateDataSource={(dataSource) => this.setState({ [stateKey]: dataSource })}
                                        />
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>

                        {/* <Row gutter={24} style={{ backgroundColor: '#FDFDFD' }}>
                            <ImageUpload
                                className={'image-swiper-home-page'}
                                label={'Upload thumbnail video'}
                                previewImage={swiperHomePage}
                                limit={1}
                                dataSource={swiperThumbnailVideoList}
                                updateDataSource={(dataSource) => this.setState({ swiperThumbnailVideoList: dataSource })}
                            />
                        </Row> */}

                    <Row gutter={24} style={{ backgroundColor: '#FFFFFF', borderBottom: "1px solid #e8e8e8" }}>
                        {this.props.languageList.map((language) => {
                            const matchedLanguage = languageMap.find(lm => lm.id === language.id);
                            if (!matchedLanguage) {
                                console.warn(`No matching language found for id: ${language.id}`);
                                return null;
                            }
                            const { variable, code } = matchedLanguage;
                            const stateKey = `swiperVideoList${variable}`;

                            return (
                                <Col span={24} key={language.id}>
                                    <div className="layout-setting-item">
                                        <div className="layout-setting-item-label">
                                            <p style={{ margin: "8px", fontWeight: '700', fontSize: 20 }}>
                                                {`Upload video (${code})`}
                                            </p>
                                        </div>
                                        <VideoUpload
                                            className={'video-swiper-home-page'}
                                            label={'Upload video'}
                                            previewImage={swiperHomePage}
                                            limit={10}
                                            dataSource={this.state[stateKey]}
                                            updateDataSource={(dataSource) => this.setState({ [stateKey]: dataSource })}
                                        />
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>


                        {/* <Row gutter={24} style={{ backgroundColor: '#FDFDFD' }}>
                            <VideoUpload
                                className={'video-swiper-home-page'}
                                label={'Upload video'}
                                previewImage={swiperHomePage}
                                limit={1}
                                dataSource={swiperVideoList}
                                updateDataSource={(dataSource) => this.setState({ swiperVideoList: dataSource })}
                            />
                        </Row> */}
                    </Form>
                </Card>
                {this.renderModalUpload()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    folderName: state.login.folderName,
    roleDetail: state.role.roleDetail,
    languageList: state.customerLanguage.languageList,
})

export default connect(mapStateToProps, null)(Swiper);

