const initialState = {
    languageList: []
};


const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INITIALIZE_CUSTOMER_LANGUAGE': {
            return Object.assign({}, state, { languageList: action.payload })
        }
        default:
            return state;
    }
}

export default roleReducer;