import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Icon, Select, notification,Tabs,InputNumber,Radio,Table,DatePicker } from 'antd';
import { get, put, post,ppost } from '../../../utils';
import moment from 'moment';
import ImageUpload from '../../../containers/ImageUpload';

const formLanguageLayout = {
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const FormItem = Form.Item;
const { Meta } = Card;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const { TabPane } = Tabs;
const { Search } = Input;
const { TextArea } = Input;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class Promotion extends Component {
    constructor(props){
        super(props)
        this.state={
            title:'',
            description:'',
            promotionLanguageList: this.props.languageList.map(item => ({
                title: '',
                description: '',
                image: '',
                imageList: [],
                language_id: item.id,
            })),
            discount_amount: "",
            discountType: "amount",
            promotionType: "P1",
            numberOfProduct: 0,

            // imageCoverList: [],

            productlist:[],
            displayProductlist: [],
            selectedRowsProduct:[],

            isPublish:0,
            state_publish_start: new Date().toISOString().slice(0, 10),
            state_publish_end: '',
            searchProduct: ""
        }
    }

    componentDidMount(){
        if (this.props.editId) {
            this.getPromotionData()
        }
        this.getProductList()
    }

    createImageObject(imageName){
        const { folderName } = this.props
        if (!imageName) return [];
        return [{
            view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${imageName}`,
            file: null,
            name: imageName
        }];
    }

    getPromotionData(){
        const { folderName, languageList } = this.props
        let url = '/api/crm/administrator/sales/promotion/' + this.props.editId
        get(url)
            .then((response) => {
                // let imageCoverList = []
                // if (response.image) {
                //     imageCoverList = [{
                //         view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${response.image}`,
                //         file: null,
                //         name: response.image
                //     }]
                // }

                // this.props.languageList.map(item => {
                //     let index = response.promotion_language_list.findIndex(itemData => itemData.language_id == item.id)
                //     if (index == -1) {
                //         response.promotion_language_list.push({ title: '', description: '', language_id: item.id })
                //     }
                // })

                languageList.map((item, indexLanguageList) => {
                    let index = response.promotion_language_list.findIndex(itemData => itemData.language_id == item.id)
                    if(index == -1){
                        response.promotion_language_list.push({ 
                            title: '',
                            description: '',
                            image: '',
                            language_id: item.id,
                            imageList: [] 
                        })
                    }else{
                    const imageList = this.createImageObject(response.promotion_language_list[index].image);
                    response.promotion_language_list[indexLanguageList].imageList = imageList
                    }
                })

                this.setState({
                    // title:response.title,
                    // description:response.description_list,
                    discount_amount: response.discount_amont,
                    discountType: response.discount_type,
                    promotionType: response.promotion_type,
                    numberOfProduct: response.number_of_product,
                    // imageCoverList: imageCoverList,
                    isPublish:response.is_publish,
                    state_publish_start: response.publish_start,
                    state_publish_end: response.publish_end,
                    promotionLanguageList: response.promotion_language_list
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    async getProductList(){
        let url = '/api/crm/administrator/sales/promotion/product'
        if (this.props.editId) {
            url = '/api/crm/administrator/sales/promotion/'+ this.props.editId +'/product'
        }
        get(url)
            .then((response) => {
                // console.log('responseresponseresponse',response)
                const selectKey = response.filter(item => item.is_selected).map(item => item.id);
                this.setState({
                    productlist: [...response],
                    displayProductlist: [...response],
                    selectedRowsProduct: selectKey
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    calOffSet(page,pageSize){
        let offSet = (page-1) * pageSize
        return offSet
    }

    async onClickOk() {
        const {
            title, description, discount_amount, discountType,promotionType,
            numberOfProduct, productlist, selectedRowsProduct,
            isPublish, state_publish_start,state_publish_end,
            promotionLanguageList
        } = this.state

        // if (!title || title == '') {
        //     openNotificationWithIcon('warning', 'System notification', 'Please enter "Title".')
        //     return
        // }
        // if (!description || description == '') {
        //     openNotificationWithIcon('warning', 'System notification', 'Please enter "Description".')
        //     return
        // }
        if(promotionLanguageList.every(item => !item.title)){
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Title".')
            return
        }
        if(promotionLanguageList.every(item => !item.description)){
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Description".')
            return
        }
        if (!discount_amount || discount_amount == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Discount Amount".')
            return
        }
        if (promotionType=="P2" && (!numberOfProduct || numberOfProduct == '')) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Number of product".')
            return
        }

        const selectSet = new Set(selectedRowsProduct);
        let filterProduct = productlist.filter(product => selectSet.has(product.id))
        if(filterProduct.length == 0){
            openNotificationWithIcon('warning', 'System notification', 'Please select "Product".')
            return
        }

        let data = {
            "title": title,
            "description": description,
            "discount_amont": discount_amount,
            "discount_type": discountType,
            "promotion_type": promotionType,
            "numberOfProduct": numberOfProduct,
            "product_list": JSON.stringify(filterProduct.map(product => product.id)),
            "is_publish": isPublish,
            "publish_start": moment(state_publish_start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            "publish_end": state_publish_end? moment(state_publish_end, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') : '9999-12-31 23:59:59',
            "edit_by": this.props.administratorId,
            "image": null,
            "promotion_language_list": promotionLanguageList
        }

        let promotionImageUploaded = await Promise.all(promotionLanguageList.map(async (languageList, index) => {
            let uploadFileList = languageList.imageList.filter(item => item.file);
            if (uploadFileList.length) {
              let formData = new FormData();
              uploadFileList.forEach((item, idx) => formData.append("image" + idx, item.file));
              let fileNameList = await this.uploadImageToServer(formData);
              if (fileNameList.length) {
                languageList.image = fileNameList[0];
              }
            } else if (languageList.imageList.length) {
              languageList.image = languageList.imageList[0].name;
            }
            languageList.imageList = []
            return languageList;
      }));
      console.log("promotionImageUploaded: ", promotionImageUploaded)


      
      data.promotion_language_list = JSON.stringify(promotionImageUploaded)
        // let uploadFileList = imageCoverList.filter(item => item.file)
        // if (uploadFileList.length) {
        //     let formData = new FormData();
        //     uploadFileList.map((item, index) => formData.append("image" + index, item.file))
        //     let fileNameList = await this.uploadImageToServer(formData)
        //     if (fileNameList.length) {
        //         data.image = fileNameList[0]
        //     }
        // } else if (imageCoverList.length) {
        //     data.image = imageCoverList[0].name
        // }
        
        let url = '/api/crm/administrator/sales/promotion';
        let update = post
        if (this.props.editId) {
            url = '/api/crm/administrator/sales/promotion/' + this.props.editId
            update = put
        }

        update(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(response.success){
                    openNotificationWithIcon('success', 'System notification', 'Save Promotion success.')
                    this.props.backpage()
                }else{
                    // switch(response.error.code){
                    //     case 'DUPLICATE_CODE':
                    openNotificationWithIcon('warning', 'System notification', 'Save Coupon error.')
                    //         return
                    // }
                }
                // this.props.backpage()
            })
            .catch(err => {
                console.log(err.message)
                openNotificationWithIcon('warning', 'System notification', 'Save Coupon error.')
            })

    }

    async uploadImageToServer(formData) {
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    onClickCancel() {
        this.props.backpage()
    }

    updatePromotionLanguage(index, name, value) {
        let promotionLanguageList = this.state.promotionLanguageList.map(item => Object.assign({}, item))
        promotionLanguageList[index][name] = value
        this.setState({ promotionLanguageList: promotionLanguageList })
    }
   
    onSearchProduct(value){
        const { productlist } = this.state
        let tempList = [...productlist]

        if(value){
            tempList = tempList.filter(item => item.name && item.name.toLowerCase().includes(value.toLowerCase()))
        }
        
        this.setState({ searchProduct: value, displayProductlist: tempList })
    }

    render() {
        const { selectedRowsProduct,displayProductlist} = this.state

        const dateFormat = 'YYYY-MM-DD';
        const columns = [{
            title: (
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <Search
                        onSearch={value => this.onSearchProduct(value)}
                    />
                    <span>Product Name</span>
                </div>
            ),
            dataIndex: 'name',
        }
        ];

        const { promotionLanguageList }  = this.state

        // const rowSelection = {
        //     onChange: (selectedRowKeys, selectedRows) => {
        //         let tempSelectedRows = Object.assign([],this.state.selectedRowsProduct)
        //         let selectedId = selectedRows.map(selected => selected.id)
        //         let index = tempSelectedRows.findIndex(item=>item.page == coursePage)
        //         let data = {
        //             page: coursePage,
        //             selectRow: selectedId
        //         }
        //         if( index != -1 ){
        //             tempSelectedRows[index] = data
        //         }else{
        //             tempSelectedRows.push(data)
        //         }
                
        //         this.setState({
        //             selectedRowsProduct: tempSelectedRows
        //         })
        //     },
        //     getCheckboxProps: record => {

        //         let index = this.state.selectedRowsProduct.findIndex(item=>item.page == coursePage)
        
        //         let data = {
        //             checked: index!=-1 ? this.state.selectedRowsProduct[index].selectRow.includes(record.id) : false
        //         }
        //         return data
                
        //     },
        // }

        const rowSelection = {
            selectedRowKeys: selectedRowsProduct,
            onSelect: (record, selectedRowKeys, selectedRows) => {
                let tempSelectedRows =Object.assign([],selectedRowsProduct)
                if(selectedRowKeys){
                    tempSelectedRows.push(record.id)
                }else{
                    tempSelectedRows = tempSelectedRows.filter(item=> item != record.id)
                }
                this.setState({
                    selectedRowsProduct: tempSelectedRows
                })
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                let tempSelectedRows =Object.assign([],selectedRowsProduct)
                let newSelectedRows = changeRows.map(changeRow => changeRow.id)

                if(selected){
                    this.setState({
                        selectedRowsProduct: [...tempSelectedRows,...newSelectedRows]
                    })
                }else{
                    tempSelectedRows = tempSelectedRows.filter(item=> !newSelectedRows.includes(item))
                    this.setState({
                        selectedRowsProduct: tempSelectedRows
                    })
                }
            },
            // getCheckboxProps: record => ({
            //     // disabled: this.state.state_usage_left > 0 ? record.name === record.name : '',
            //     name: record.name,
            //     checked: record.is_selected,
            // }),
        }

        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayoutSummary = {
            labelCol: {
                xs: { span: 12 },
                sm: { span: 12 },
            },
            wrapperCol: {
                xs: { span: 12 },
                sm: { span: 12 },
            },
        };
        return (
            <div className='categoryarticle-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh', overflow: "scroll" }}>
                    <Row className="header-card" >
                        <Col span={12} style={{ textAlign: 'left', cursor: 'pointer' }} onClick={() => this.props.backpage()}>
                            <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                            <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{this.props.editId ? 'Edit Promotion' : 'Create Promotion'}</span>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                            <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickOk()} >{'Save'}</Button>
                        </Col>
                    </Row>
                    {/* <Card className='cardstyle'> */}
                    <Tabs onChange={() => null} type="card">
                        <TabPane tab={"General"} key={"1"}>
                            <Card className='cardstyle'>
                                {/* <Row gutter={24}>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Title'} required={true}>
                                            <Input
                                                value={this.state.title}
                                                onChange={(event) => this.setState({ title: event.target.value })}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Description'} required={true}>
                                            <Input
                                                value={this.state.description}
                                                onChange={(event) => this.setState({ description: event.target.value })}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row> */}
                                {promotionLanguageList.map((item, index) => {
                                    return (
                                        <Card style={{ marginBottom: 10 }} className='language-card'>
                                            <Row gutter={24}>
                                                <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                                                    <FormItem {...formLanguageLayout} label={'Line ID'}>
                                                        <p style={{ margin: 0, fontSize: 14, padding: 3 }}>{`0000${index + 1}`}</p>
                                                    </FormItem>
                                                </Col>
                                                <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                                    <FormItem {...formLanguageLayout} label={'Language'} required={true} style={{textAlign: "start"}}>
                                                        <Select
                                                            allowClear={true}
                                                            value={item.language_id}
                                                            onChange={(value) => this.updatePromotionLanguage(index, 'language_id', value)}
                                                        >
                                                            {this.props.languageList.length ?
                                                                this.props.languageList.map((item, index) => 
                                                                <Option 
                                                                    key={index} 
                                                                    value={item.id} 
                                                                    disabled={promotionLanguageList.map(r => r.language_id).includes(item.id)}
                                                                >
                                                                    {item.name}
                                                                </Option>)
                                                                : null}
                                                        </Select>
                                                    </FormItem>
                                                </Col>
                                                <Col xs={24} sm={24} md={10} lg={10} xl={10} >
                                                    <FormItem {...formLanguageLayout} label={'Title'} required={true}>
                                                        <Input
                                                            value={item.title}
                                                            onChange={(event) => this.updatePromotionLanguage(index, 'title', event.target.value)}
                                                        />
                                                    </FormItem>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                    <FormItem {...formLanguageLayout} label={'Description'} required={true}>
                                                        <TextArea
                                                            autosize={{ minRows: 6 }}
                                                            value={item.description}
                                                            onChange={(event) => this.updatePromotionLanguage(index, 'description', event.target.value)}
                                                        />
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Card>
                                    )
                                })}
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Discount Amount'} required={true}>
                                            <InputNumber
                                                value={this.state.discount_amount}
                                                onChange={(value) => this.setState({ discount_amount: value })}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Discount Type'} required={true}>
                                            <RadioGroup
                                                // disabled={isUsed}
                                                onChange={(event) => this.setState({ discountType: event.target.value })}
                                                value={this.state.discountType}
                                            >
                                                <Radio value={"amount"}>{'Amount'}</Radio>
                                                <Radio value={"percent"}>{'Percent'}</Radio>
                                            </RadioGroup>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Promotion Type'} required={true}>
                                            <RadioGroup
                                                // disabled={isUsed}
                                                onChange={(event) => this.setState({ promotionType: event.target.value })}
                                                value={this.state.promotionType}
                                            >
                                                <Radio value={"P1"}>{'Set'}</Radio>
                                                <Radio value={"P2"}>{'Group'}</Radio>
                                            </RadioGroup>
                                        </FormItem>
                                    </Col>
                                    {this.state.promotionType == "P2" ?
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Number of product'} required={false}>
                                            
                                                <InputNumber
                                                    value={this.state.numberOfProduct}
                                                    onChange={(value) => this.setState({ numberOfProduct: value })}
                                                />
                                            </FormItem>
                                        </Col>
                                    :
                                        null
                                    }
                                </Row>
                                
                            </Card>
                        </TabPane>
                        <TabPane tab={"Photo"} key={"5"}>
                            {/* <Card className='cardstyle'>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={8} xl={8} > */}
                                        {/* <FormItem {...formItemLayout} label={'Image'}>
                                            <ImageUpload
                                                label={'Upload image cover'}
                                                limit={1}
                                                dataSource={this.state.imageCoverList}
                                                updateDataSource={(dataSource) => this.setState({ imageCoverList: dataSource })}
                                            />
                                        </FormItem> */}
                            {this.state.promotionLanguageList.map((item, index) => {
                                return (
                                    <Card className='cardstyle w-100' style={{ marginBottom: 10 }}>
                                        <Row gutter={24}>
                                            <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                                                <FormItem {...formItemLayout} label={'Line ID'}>
                                                    <p style={{ margin: 0, fontSize: 14, padding: 3 }}>{`0000${index + 1}`}</p>
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                                <FormItem {...formItemLayout} label={'Language'} required={true}>
                                                    <Select
                                                        allowClear={true}
                                                        value={item.language_id}
                                                        onChange={(value) => this.updatePromotionLanguage(index, 'language_id', value)}
                                                    >
                                                        {this.props.languageList.length ?
                                                            this.props.languageList.map((item, index) => <Option key={index} value={item.id} disabled={this.state.promotionLanguageList.map(r => r.language_id).includes(item.id)} >{item.name}</Option>)
                                                            : null}
                                                    </Select>
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                <FormItem {...formItemLayout} label={'Image'} required={true}>
                                                    <ImageUpload
                                                        label={'Upload image cover'}
                                                        limit={1}
                                                        dataSource={item.imageList}
                                                        updateDataSource={(dataSource) => this.updatePromotionLanguage(index, 'imageList', dataSource)}
                                                        // updateDataSource={(dataSource) => this.setState({ imageCoverList: dataSource })}
                                                    />
                                                </FormItem>
                                            </Col>
                    
                                        </Row>
                                    </Card>
                                )
                            })}

                                    {/* </Col>
                                </Row>
                            </Card> */}
                        </TabPane>
                        <TabPane tab={"Product"} key={"2"}>
                            <Card className='cardstyle' style={{ height: "72vh", overflow:"scroll" }}>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={12} xl={12} >
                                        <Table
                                            bordered={true}
                                            rowKey={record => record.id}
                                            rowSelection={rowSelection}
                                            columns={columns}
                                            dataSource={displayProductlist}

                                            // pagination={{ position: 'bottom', size: "small", pageSize: 10,total: totalProduct }}
                                        /> 
                                    </Col>
                                </Row>
                                
                            </Card>
                        </TabPane>
                        <TabPane tab={"Publishing"} key={"4"}>
                            <Card className='cardstyle'>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={12} xl={12} >
                                        <FormItem {...formItemLayout} label={'Publishing'} required={true}>
                                            <Select
                                                // showSearch={true}
                                                // optionFilterProp={'title'}
                                                value={this.state.isPublish}
                                                onChange={(value) => this.setState({isPublish: value})}
                                            >
                                            <Option value={1} title={'Yes'}>{'Yes'}</Option>
                                            <Option value={0} title={'No'}>{'No'}</Option>
                                        </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Publishing'} required={true}>
                                            <DatePicker 
                                                value={this.state.state_publish_start && moment(this.state.state_publish_start, dateFormat)} 
                                                format={dateFormat} 
                                                onChange={(value, dateString) => this.setState({ state_publish_start: dateString })} 
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Publishing'} required={false}>
                                            <DatePicker 
                                                value={this.state.state_publish_end && moment(this.state.state_publish_end, dateFormat)} 
                                                format={dateFormat} 
                                                onChange={(value, dateString) => this.setState({ state_publish_end: dateString })} 
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Card>
                        </TabPane>
                    </Tabs>
                    {/* </Card> */}
                </div>
        );
    }
}

const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    folderName: state.login.folderName,
    languageList: state.customerLanguage.languageList,
})

export default connect(mapStateToProps)(Promotion);