import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Icon, notification, Menu } from 'antd';
import { post } from '../../../utils';
import { Link } from 'react-router-dom'
import './style.scss'

import StartedPattern from './StartedPattern'

import HomeVideoSwiper from './HomeVideoSwiper'
import HomeSwiper from './HomeSwiper'
import HomeBannerSwiper from './HomeBannerSwiper'
import HomeLayoutMenu from './HomeLayoutMenu'
import HomeLayoutProductLayout from './HomeLayoutProductLayout'
import HomeLayoutArticleLayout from './HomeLayoutArticleLayout'
import HomeVIPBanner from './HomeVIPBanner'

import ProductLayout from './ProductLayout'
import ArticleLayout from './ArticleLayout'
import ProductDetailLayoutButton from './ProductDetailLayoutButton'
const { SubMenu } = Menu;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class Mobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {



            display: 1,
            languageList: [],
            termsAndConditionsLanguageList: [],


            indexMenu: 0,
            indexSubMenu: 0,
            menuList: [
                {
                    key: 'started',
                    title: 'Started Screen',
                    iconName: 'mobile',
                    subMenuList: [
                        { key: 'home-01', title: 'Pattern', component: <StartedPattern  {...this.props} /> },
                    ]
                },
                {
                    key: 'home',
                    title: 'Home Screen',
                    iconName: 'mobile',
                    subMenuList: [
                        { key: 'home-01', title: 'Video', component: <HomeVideoSwiper  {...this.props} /> ,isTechnicianApp:true },
                        { key: 'home-02', title: 'Swiper', component: <HomeSwiper  {...this.props} /> },
                        { key: 'home-03', title: 'Banner', component: <HomeBannerSwiper  {...this.props} /> ,isTechnicianApp:true },
                        { key: 'home-04', title: 'Layout Menu', component: <HomeLayoutMenu /> },
                        { key: 'home-05', title: 'Product Layout', component: <HomeLayoutProductLayout {...this.props} /> },
                        { key: 'home-06', title: 'Article Layout', component: <HomeLayoutArticleLayout {...this.props} /> },
                    ]
                },
                // {
                //     key: 'product-list',
                //     title: 'Product List Screen',
                //     iconName: 'mobile',
                //     subMenuList: [
                //         { key: 'product-list-01', title: 'Layout List', component: <ProductLayout {...this.props} /> },
                //     ]
                // },
                {
                    key: 'article-list',
                    title: 'Article List Screen',
                    iconName: 'mobile',
                    subMenuList: [
                        { key: 'article-list-01', title: 'Layout List', component: <ArticleLayout {...this.props} /> },
                    ]
                },
                {
                    key: 'product-detail',
                    title: 'Product Detail Screen',
                    iconName: 'mobile',
                    subMenuList: [
                        { key: 'product-detail-01', title: 'Layout Button', component: <ProductDetailLayoutButton {...this.props} /> },
                    ]
                },
                {
                    key: 'vip-upgrage-page',
                    title: 'VIP Upgrage page',
                    iconName: 'mobile',
                    isTechnicianApp:true,
                    subMenuList: [
                        { key: 'vip-upgrage-page-01', title: 'VIP Page', component: <HomeVIPBanner /> },
                    ]
                }
            ]
        }
    }




    componentDidMount() {
    }

    validateTermsAndConditionsLanguageList() {
        let result = {
            success: true,
            message: 'System notification',
            description: ''
        }
        this.state.termsAndConditionsLanguageList.map((item, index) => {
            if (result.success) {
                if (!item.language_id || item.language_id == '') {
                    result.success = false
                    result.description = `Please enter "Language" in line ID 0000${index + 1} .`
                    return
                }
                if (!item.name || item.name == '') {
                    result.success = false
                    result.description = `Please enter "Name" in line ID 0000${index + 1} .`
                    return
                }
            }
        })
        return result
    }

    updateTermsAndConditions() {
        const { display } = this.state
        let validateTermsAndConditionsLanguageListResult = this.validateTermsAndConditionsLanguageList()
        if (!validateTermsAndConditionsLanguageListResult.success) {
            openNotificationWithIcon('warning', 'System notification', validateTermsAndConditionsLanguageListResult.description)
            return
        }

        let termsAndConditionsLanguageList = this.state.termsAndConditionsLanguageList.map(item => {
            let tempStringify = { detail_list: JSON.stringify(item.detail_list), accept_list: JSON.stringify(item.accept_list) }
            return Object.assign({}, item, tempStringify)
        })
        let data = {
            display: display,
            terms_and_conditions_language_list: JSON.stringify(termsAndConditionsLanguageList),
        }

        let url = '/api/crm/administrator/privacy/policy';
        post(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                openNotificationWithIcon('success', 'System notification', 'Save swiper success.')
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickOk() {
        this.updateTermsAndConditions()
    }

    onClickCancel() {
        this.getTermsAndConditions()
    }

    onClickMenu(indexMenu, indexSubMenu) {
        this.setState({ indexMenu: indexMenu, indexSubMenu: indexSubMenu })
    }

    render() {
        const { menuList } = this.state
        let data = menuList.map(item=>{
            let subMenuList = item.subMenuList.filter(itemMenu=> {
                if(this.props.mainCustomerId !=5 && itemMenu.isTechnicianApp){
                    return false
                }
                return true
                
            })
            item.subMenuList = subMenuList
            return item
        })
        return (
            <div className='swiper-page-wrapper' style={{ backgroundColor: '#ffffff', height: '90vh' }}>
                <Row className="header-card-swiper" >
                    <Col span={12} style={{ textAlign: 'left',cursor: 'pointer' }}>
                        <Link to={'/'}>
                            <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                            <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{"Screen Mobile"}</span>
                        </Link>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                    </Col>
                </Row>
                <Card className='cardstyle'>
                    <Row>
                        <Col xs={12} sm={10} md={8} lg={6} xl={5} >
                            <Menu
                                onClick={this.handleClick}
                                style={{ width: 256 }}
                                defaultSelectedKeys={['home']}
                                defaultOpenKeys={['home-01']}
                                mode="inline"
                            >
                                {data.map((menu, indexMenu) => {
                                    return (
                                        <SubMenu
                                            key={menu.key}
                                            title={
                                                <span>
                                                    <Icon type={menu.iconName} />
                                                    <span>{menu.title}</span>
                                                </span>
                                            }
                                        >
                                            {menu.subMenuList.map((subMenu, indexSubMenu) => {
                                                return (
                                                    <Menu.Item key={subMenu.key} onClick={() => this.onClickMenu(indexMenu, indexSubMenu)}>{subMenu.title}</Menu.Item>
                                                )
                                            })}
                                        </SubMenu>
                                    )
                                })}
                            </Menu>
                        </Col>
                        <Col xs={12} sm={14} md={16} lg={18} xl={19}>
                            {data[this.state.indexMenu].subMenuList[this.state.indexSubMenu].component}
                        </Col>
                    </Row>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    mainCustomerId: state.login.mainCustomerId,
})

export default connect(mapStateToProps)(Mobile);
