import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Modal,notification, Select } from 'antd';
import { get, post, put, ppost } from '../../../utils';
import ImageUpload from '../../../containers/ImageUpload';
import config from '../../../config.json'
import './style.scss'
notification.config({
    placement: 'topRight',
    duration: 1,
});
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};
const FormItem = Form.Item;
const { Meta } = Card;
const Option = Select.Option;

class CategoryProductModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            productCategoryLanguageList: [],
            imageCoverList: [],
        }
    }

    getCategoryProductDetail() {
        const { editId, folderName } = this.props
        let url = '/api/administrator/crm/category/product/' + editId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }

                let imageCoverList = []
                if (response.image) {
                    imageCoverList = [{
                        view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${response.image}`,
                        file: null,
                        name: response.image
                    }]
                }

                this.props.languageList.map(item => {
                    let index = response.product_category_language_list.findIndex(itemData => itemData.language_id == item.id)
                    if (index == -1) {
                        response.product_category_language_list.push({ name: '', language_id: item.id })
                    }
                })

                this.setState({
                    name: response.name,
                    productCategoryLanguageList: response.product_category_language_list,
                    imageCoverList: imageCoverList,
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    async componentDidMount() {
        if (this.props.editId) {
            this.getCategoryProductDetail()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.visible && this.props.visible){
            let detailList = []
            this.props.languageList.map(item=>{
                detailList.push({ 
                    name: '',
                    image: '',
                    language_id: item.id
                 })
            })
            console.log("DIDMOUNT")
            this.setState({
                productCategoryLanguageList: detailList,
                name: "",
                imageCoverList: []
            })
        }
        
        if (prevProps.visible != this.props.visible) {
            if (this.props.editId) {
                this.getCategoryProductDetail()
            }
        }

    }

    async uploadImageToServer(formData) {
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    async updateCategoryProduct() {
        const { name, imageCoverList, productCategoryLanguageList } = this.state
        // if (!name || name == '') {
        //     openNotificationWithIcon('warning', 'System notification', 'Please enter "Category Product Name".')
        //     return
        // }
        if(productCategoryLanguageList.every(item => !item.name)){
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Category Product Name".')
            return
        }
        let data = {
            product_category_language_list: productCategoryLanguageList,
            // name: name,
            user: this.props.administratorId,
            image: null,
        }

        let uploadFileList = imageCoverList.filter(item => item.file)
        if (uploadFileList.length) {
            let formData = new FormData();
            uploadFileList.map((item, index) => formData.append("image" + index, item.file))
            let fileNameList = await this.uploadImageToServer(formData)
            if (fileNameList.length) {
                data.image = fileNameList[0]
            }
        } else if (imageCoverList.length) {
            data.image = imageCoverList[0].name
        }
        if (this.props.editId) {
            let url = '/api/administrator/crm/category/product/' + this.props.editId
            put(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.setState({
                        name: '',
                        imageCoverList: []
                    })
                    this.props.onClose()
                })
                .catch(err => {
                    console.log(err.message)
                })
        } else {
            let url = '/api/administrator/crm/category/product';
            post(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.setState({
                        name: '',
                        imageCoverList: []
                    })
                    this.props.onClose()
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }

    onClickOk() {
        this.updateCategoryProduct()
    }

    onClickCancel() {
        this.setState({
            name: '',
            imageCoverList: []
        })
        this.props.onClose()
    }

    updateNameLanguage(index, name, value) {
        let productCategoryLanguageList = this.state.productCategoryLanguageList.map(item => Object.assign({}, item))
        productCategoryLanguageList[index][name] = value
        this.setState({ productCategoryLanguageList: productCategoryLanguageList })
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 4 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 20 },
                sm: { span: 20 },
            },
        };
        const formLanguageLayout = {
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const { visible } = this.props
        return (
            <div>
                <Modal
                    title={this.props.editId ? 'Edit Category Product' : 'Create Category Product'}
                    visible={visible}
                    onOk={() => this.onClickOk()}
                    onCancel={() => this.onClickCancel()}
                    style={{ textAlign: 'center' }}
                    footer={[
                        <Button key="back" onClick={() => this.onClickCancel()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} >
                            {'Cancel'}
                        </Button>,
                        <Button key="submit" onClick={() => this.onClickOk()} style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} >
                            {this.props.editId ? 'Edit' : 'Create'}
                        </Button>,
                    ]}
                >
                    <Row gutter={24} style={{textAlign:'center'}}>
                        {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <FormItem {...formItemLayout} label={'Name'} required={true}>
                                <Input
                                    value={this.state.name}
                                    onChange={(event) => this.setState({ name: event.target.value })}
                                />
                            </FormItem>
                        </Col> */}
                            {this.state.productCategoryLanguageList.map((item, index) => {
                                return (
                                    <Card style={{ marginBottom: 10 }} className='card-align-left'>
                                        <Row gutter={24}>
                                            <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                                                <FormItem {...formLanguageLayout} label={'Line ID'}>
                                                    <p style={{ margin: 0, fontSize: 14, padding: 3 }}>{`0000${index + 1}`}</p>
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={18} lg={18} xl={18} >
                                                <FormItem {...formLanguageLayout} label={'Language'} required={true} style={{textAlign: "start"}}>
                                                    <Select
                                                        allowClear={true}
                                                        value={item.language_id}
                                                        onChange={(value) => this.updateNameLanguage(index, 'language_id', value)}
                                                        className="block"
                                                    >
                                                        {this.props.languageList.length ?
                                                            this.props.languageList.map((item, index) => 
                                                            <Option 
                                                                key={index} 
                                                                value={item.id} 
                                                                disabled={this.state.productCategoryLanguageList.map(r => r.language_id).includes(item.id)}
                                                            >
                                                                {item.name}
                                                            </Option>)
                                                            : null}
                                                    </Select>
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                <FormItem {...formLanguageLayout} label={'Name'} required={true}>
                                                    <Input
                                                        value={item.name}
                                                        onChange={(event) => this.updateNameLanguage(index, 'name', event.target.value)}
                                                    />
                                                </FormItem>
                                            </Col>
                                        </Row>
                                    </Card>
                                )
                            })}
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <ImageUpload
                                label={'Upload image cover'}
                                limit={1}
                                dataSource={this.state.imageCoverList}
                                updateDataSource={(dataSource) => this.setState({ imageCoverList: dataSource })}
                            />
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}

CategoryProductModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
}

CategoryProductModal.defaultProps = {
    visible: false,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    folderName: state.login.folderName,
    languageList: state.customerLanguage.languageList,
})

export default connect(mapStateToProps, null)(CategoryProductModal);

