import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Icon, Select, notification,Tabs,InputNumber,Radio,Table,DatePicker } from 'antd';
import { get, put, post } from '../../../utils';
import moment from 'moment';

const formLanguageLayout = {
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const FormItem = Form.Item;
const { Meta } = Card;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const { TabPane } = Tabs;
const { Search } = Input;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class Coupon extends Component {
    constructor(props){
        super(props)
        this.state={
            title:'',
            couponLanguageList: this.props.languageList.map(item => ({
                title: '',
                language_id: item.id,
            })),
            code:'',
            discount_amount: "",
            discountType: "amount",
            state_usage_limit: "",
            state_usage_left: 0,

            productlist:[],
            displayProductlist:[],
            selectedRowsProduct:[],
            coursePage:1,
            totalProduct:0,

            paymentList:[],
            selectedRowsPayment:[],

            isPublish:0,
            state_publish_start: new Date().toISOString().slice(0, 10),
            state_publish_end: '',
            searchProduct: ""
        }
    }

    componentDidMount(){
        if (this.props.editId) {
            this.getCouponData()
        }
        this.getProductList()
        this.getPayment()
    }

    getCouponData(){
        let url = '/api/crm/administrator/sales/coupon/' + this.props.editId
        get(url)
            .then((response) => {
                this.props.languageList.map(item => {
                    let index = response.coupon_language_list.findIndex(itemData => itemData.language_id == item.id)
                    if (index == -1) {
                        response.coupon_language_list.push({ title: '', language_id: item.id })
                    }
                })
                this.setState({
                    title:response.title,
                    code:response.code,
                    discount_amount: response.discount_amont,
                    discountType: response.discount_type,
                    state_usage_limit: response.usage_limit,
                    state_usage_left: response.usage_left,
                    isPublish:response.is_publish,
                    state_publish_start: response.publish_start,
                    state_publish_end: response.publish_end,
                    couponLanguageList: response.coupon_language_list
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    async getProductList(){
        let url = '/api/crm/administrator/sales/coupon/product'
        if (this.props.editId) {
            url = '/api/crm/administrator/sales/coupon/'+ this.props.editId +'/product'
        }
        get(url)
            .then((response) => {
                const selectKey = response.filter(item => item.is_selected).map(item => item.id);
                this.setState({
                    productlist: [...response],
                    displayProductlist: [...response],
                    selectedRowsProduct: selectKey
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    getPayment(){
        let url = '/api/crm/administrator/sales/coupon/payment/method'
        if (this.props.editId) {
            url = '/api/crm/administrator/sales/coupon/'+ this.props.editId +'/payment/method'
        }
        get(url)
            .then((response) => {
                this.setState({
                    paymentList: response,
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    calOffSet(page,pageSize){
        let offSet = (page-1) * pageSize
        return offSet
    }

    onClickOk() {
        const {
            title, code, discount_amount, discountType,
            state_usage_limit, selectedRowsProduct, selectedRowsPayment,
            isPublish, state_publish_start,state_publish_end,
            couponLanguageList
        } = this.state

        // if (!title || title == '') {
        //     openNotificationWithIcon('warning', 'System notification', 'Please enter "Title".')
        //     return
        // }
        if (couponLanguageList.every(item => !item.title)) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Title".')
            return
        }

        if (!code || code == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Code".')
            return
        }
        if (!discount_amount || discount_amount == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Discount Amount".')
            return
        }
        if (!state_usage_limit || state_usage_limit == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Usage Limit".')
            return
        }

        // let dataSelectProduct = []
        // if(selectedRowsProduct.length > 0){
        //     selectedRowsProduct.map(item=>{
        //         let data = item.selectRow.map(itemSelet=> {
        //             dataSelectProduct.push(itemSelet)
        //         } )
        //     })
        // }
        const selectSet = new Set(selectedRowsProduct);
        let filterProduct = this.state.productlist.filter(product => selectSet.has(product.id))
        if(filterProduct.length == 0){
            openNotificationWithIcon('warning', 'System notification', 'Please select "Product".')
            return
        }

        let data = {
            "title": title,
            "code": code,
            "discount_amont": discount_amount,
            "discount_type": discountType,
            "usage_limit": state_usage_limit,
            "payment_method": JSON.stringify(this.state.paymentList.filter(paymentmethod => paymentmethod.is_selected == true).map(paymentmethod => paymentmethod.id)),
            "product_list": JSON.stringify(filterProduct.map(product => product.id)),
            "is_publish": isPublish,
            "publish_start": moment(state_publish_start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            "publish_end": state_publish_end? moment(state_publish_end, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') : '9999-12-31 23:59:59',
            "edit_by": this.props.administratorId,
            "coupon_language_list": couponLanguageList
        }
        
        let url = '/api/crm/administrator/sales/coupon';
        let update = post
        if (this.props.editId) {
            url = '/api/crm/administrator/sales/coupon/' + this.props.editId
            update = put
        }

        update(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if(response.success){
                    openNotificationWithIcon('success', 'System notification', 'Save Coupon success.')
                    this.props.backpage()
                }else{
                    switch(response.error.code){
                        case 'DUPLICATE_CODE':
                            openNotificationWithIcon('warning', 'System notification', 'Save Coupon error. Duplicate code')
                            return
                    }
                }
                // this.props.backpage()
            })
            .catch(err => {
                console.log(err.message)
                openNotificationWithIcon('warning', 'System notification', 'Save Coupon error.')
            })

    }
    onClickCancel() {
        this.props.backpage()
    }

    updateCouponLanguage(index, name, value) {
        let couponLanguageList = this.state.couponLanguageList.map(item => Object.assign({}, item))
        couponLanguageList[index][name] = value
        this.setState({ couponLanguageList: couponLanguageList })
    }
   
    onSearchProduct(value){
        const { productlist } = this.state
        let tempList = [...productlist]

        if(value){
            tempList = tempList.filter(item => item.name && item.name.toLowerCase().includes(value.toLowerCase()))
        }
        this.setState({ searchProduct: value, displayProductlist: tempList })
    }
    render() {
        const { selectedRowsProduct, displayProductlist, totalProduct, coursePage, couponLanguageList } = this.state
        const dateFormat = 'YYYY-MM-DD';
        const columns = [{
            title: (
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <Search
                        onSearch={value => this.onSearchProduct(value)}
                    />
                    <span>Product Name</span>
                </div>
            ),
            dataIndex: 'name',
        }
        ];

        // const columnsPaymentMethod = [{
        //     title: 'Payment Method Name',
        //     dataIndex: 'name',
        //     // render: text => {text},
        // }
        // ];

        // const rowSelection = {
        //     onChange: (selectedRowKeys, selectedRows) => {
        //         let tempSelectedRows = Object.assign([],this.state.selectedRowsProduct)
        //         let selectedId = selectedRows.map(selected => selected.id)
        //         let index = tempSelectedRows.findIndex(item=>item.page == coursePage)
        //         let data = {
        //             page: coursePage,
        //             selectRow: selectedId
        //         }
        //         if( index != -1 ){
        //             tempSelectedRows[index] = data
        //         }else{
        //             tempSelectedRows.push(data)
        //         }
                
        //         this.setState({
        //             selectedRowsProduct: tempSelectedRows
        //         })
        //     },
        //     getCheckboxProps: record => {

        //         let index = this.state.selectedRowsProduct.findIndex(item=>item.page == coursePage)
        
        //         let data = {
        //             checked: index!=-1 ? this.state.selectedRowsProduct[index].selectRow.includes(record.id) : false
        //         }
        //         return data
                
        //     },
        // }
        const rowSelection = {
            selectedRowKeys: selectedRowsProduct,
            onSelect: (record, selectedRowKeys, selectedRows) => {
                let tempSelectedRows =Object.assign([],selectedRowsProduct)
                if(selectedRowKeys){
                    tempSelectedRows.push(record.id)
                }else{
                    tempSelectedRows = tempSelectedRows.filter(item=> item != record.id)
                }
                this.setState({
                    selectedRowsProduct: tempSelectedRows
                })
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                let tempSelectedRows =Object.assign([],selectedRowsProduct)
                let newSelectedRows = changeRows.map(changeRow => changeRow.id)

                if(selected){
                    this.setState({
                        selectedRowsProduct: [...tempSelectedRows,...newSelectedRows]
                    })
                }else{
                    tempSelectedRows = tempSelectedRows.filter(item=> !newSelectedRows.includes(item))
                    this.setState({
                        selectedRowsProduct: tempSelectedRows
                    })
                }
            },
        }

        
        const rowSelectionPayment = {
            onSelect: (record, selectedRowKeys, selectedRows) => {
                record.is_selected = selectedRowKeys
                this.setState({
                    selectedRowsPayment: selectedRows
                })
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                let newSelectedRows = changeRows.map(changeRow => { changeRow.is_selected = selected })
                this.setState({
                    selectedRowsPayment: selectedRows
                })
            },
            getCheckboxProps: record => ({
                // disabled: this.state.state_usage_left > 0 ? record.name === record.name : '',
                name: record.name,
                checked: record.is_selected,
            }),
        }

        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayoutSummary = {
            labelCol: {
                xs: { span: 12 },
                sm: { span: 12 },
            },
            wrapperCol: {
                xs: { span: 12 },
                sm: { span: 12 },
            },
        };
        return (
            <div className='categoryarticle-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh', overflow: "scroll" }}>
                    <Row className="header-card" >
                        <Col span={12} style={{ textAlign: 'left', cursor: 'pointer' }} onClick={() => this.props.backpage()}>
                            <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                            <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{this.props.editId ? 'Edit Coupon' : 'Create Coupon'}</span>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                            <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickOk()} >{'Save'}</Button>
                        </Col>
                    </Row>
                    {/* <Card className='cardstyle'> */}
                    <Tabs onChange={() => null} type="card">
                        <TabPane tab={"General"} key={"1"}>
                            <Card className='cardstyle'>
                                {couponLanguageList.map((item, index) => {
                                    return (
                                        <Card style={{ marginBottom: 10 }} className='language-card'>
                                            <Row gutter={24}>
                                                <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                                                    <FormItem {...formLanguageLayout} label={'Line ID'}>
                                                        <p style={{ margin: 0, fontSize: 14, padding: 3 }}>{`0000${index + 1}`}</p>
                                                    </FormItem>
                                                </Col>
                                                <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                                    <FormItem {...formLanguageLayout} label={'Language'} required={true} style={{textAlign: "start"}}>
                                                        <Select
                                                            allowClear={true}
                                                            value={item.language_id}
                                                            onChange={(value) => this.updateCouponLanguage(index, 'language_id', value)}
                                                        >
                                                            {this.props.languageList.length ?
                                                                this.props.languageList.map((item, index) => 
                                                                <Option 
                                                                    key={index} 
                                                                    value={item.id} 
                                                                    disabled={couponLanguageList.map(r => r.language_id).includes(item.id)}
                                                                >
                                                                    {item.name}
                                                                </Option>)
                                                                : null}
                                                        </Select>
                                                    </FormItem>
                                                </Col>
                                                <Col xs={24} sm={24} md={10} lg={10} xl={10} >
                                                    <FormItem {...formLanguageLayout} label={'Title'} required={true}>
                                                        <Input
                                                            value={item.title}
                                                            onChange={(event) => this.updateCouponLanguage(index, 'title', event.target.value)}
                                                        />
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Card>
                                    )
                                })}
                                <Row gutter={24}>
                                    {/* <Col sm={24} md={24} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Title'} required={true}>
                                            <Input
                                                value={this.state.title}
                                                onChange={(event) => this.setState({ title: event.target.value })}
                                            />
                                        </FormItem>
                                    </Col> */}
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Code'} required={true}>
                                            <Input
                                                value={this.state.code}
                                                onChange={(event) => this.setState({ code: event.target.value })}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Discount Amount'} required={true}>
                                            <InputNumber
                                                value={this.state.discount_amount}
                                                onChange={(value) => this.setState({ discount_amount: value })}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Discount Type'} required={true}>
                                            <RadioGroup
                                                // disabled={isUsed}
                                                onChange={(event) => this.setState({ discountType: event.target.value })}
                                                value={this.state.discountType}
                                            >
                                                <Radio value={"amount"}>{'Amount'}</Radio>
                                                <Radio value={"percent"}>{'Percent'}</Radio>
                                            </RadioGroup>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Usage Limit'} required={true}>
                                            <InputNumber
                                                value={this.state.state_usage_limit}
                                                onChange={(value) => this.setState({ state_usage_limit: value })}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Usage Left'} required={false}>
                                            {/* {'10'} */}
                                            {this.state.state_usage_limit - this.state.state_usage_left}
                                        </FormItem>
                                    </Col>
                                </Row>
                                
                            </Card>
                        </TabPane>
                        <TabPane tab={"Product"} key={"2"}>
                            <Card className='cardstyle' style={{ height: "72vh", overflow:"scroll" }}>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={12} xl={12} >
                                        <Table
                                            bordered={true}
                                            rowKey={record => record.id}
                                            rowSelection={rowSelection}
                                            columns={columns}
                                            dataSource={displayProductlist}
                                            // pagination={{ position: 'bottom', size: "small", pageSize: 10,total: totalProduct }}
                                        /> 
                                    </Col>
                                </Row>
                                
                            </Card>
                        </TabPane>
                        {/* <TabPane tab={"Payment Method"} key={"3"}>
                            <Card className='cardstyle'>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <Table
                                            bordered={true}
                                            rowKey={record => record.id}
                                            rowSelection={rowSelectionPayment}
                                            columns={columnsPaymentMethod}
                                            dataSource={this.state.paymentList}
                                        /> 
                                    
                                    </Col>
                                </Row>
                                
                            </Card>
                        </TabPane> */}
                        <TabPane tab={"Publishing"} key={"4"}>
                            <Card className='cardstyle'>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={12} xl={12} >
                                        <FormItem {...formItemLayout} label={'Publishing'} required={true}>
                                            <Select
                                                // showSearch={true}
                                                // optionFilterProp={'title'}
                                                value={this.state.isPublish}
                                                onChange={(value) => this.setState({isPublish: value})}
                                            >
                                            <Option value={1} title={'Yes'}>{'Yes'}</Option>
                                            <Option value={0} title={'No'}>{'No'}</Option>
                                        </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Publishing'} required={true}>
                                            <DatePicker 
                                                value={this.state.state_publish_start && moment(this.state.state_publish_start, dateFormat)} 
                                                format={dateFormat} 
                                                onChange={(value, dateString) => this.setState({ state_publish_start: dateString })} 
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <FormItem {...formItemLayout} label={'Publishing'} required={false}>
                                            <DatePicker 
                                                value={this.state.state_publish_end && moment(this.state.state_publish_end, dateFormat)} 
                                                format={dateFormat} 
                                                onChange={(value, dateString) => this.setState({ state_publish_end: dateString })} 
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Card>
                        </TabPane>
                    </Tabs>
                    {/* </Card> */}
                </div>
        );
    }
}

const mapStateToProps = (state) => ({
    administratorId: state.login.id,
    languageList: state.customerLanguage.languageList,
})

export default connect(mapStateToProps)(Coupon);