import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, InputNumber, Icon, Select, Tabs, Table } from 'antd';
import { get, put, post } from '../../../utils';
import defaultSquareSrc from '../../../resource/default/300x300-square.png'
import './style.scss'

const { TabPane } = Tabs;

const FormItem = Form.Item;
const { Meta } = Card;
const Option = Select.Option;

const urlUpload = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads`

class OrderProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productList: [],
            productSkuList: [],

            dataSource: [],
            columns: [
                {
                    title: 'Line No.',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'left ',
                    width: '10%',
                    className: "original-style-input-table",
                    render: (text, item, index) => (index + 1).toString().padStart(5, 0)
                },
                {
                    title: 'Product',
                    dataIndex: 'product_id',
                    key: 'product_id',
                    align: 'center',
                    // width: '20%',
                    className: "original-style-input-table",
                    render: (text, item, index) => this.renderProductColumn(text, item, index)
                },
                {
                    title: 'Product Sku',
                    dataIndex: 'product_sku_id',
                    key: 'product_sku_id',
                    align: 'center',
                    width: '20%',
                    className: "original-style-input-table",
                    render: (text, item, index) => this.renderProductSkuColumn(text, item, index)
                },
                {
                    title: 'Price',
                    dataIndex: 'price',
                    key: 'price',
                    align: 'center',
                    width: '10%',
                    className: "original-style-input-table",
                    render: (text, item, index) => <span>{Intl.NumberFormat('th-TH', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.product_price)}</span> 
                },
                {
                    title: 'Quantity',
                    dataIndex: 'quantity',
                    key: 'quantity',
                    align: 'center',
                    width: '10%',
                    className: "original-style-input-table",
                    render: (text, item, index) => this.renderQuantityColumn(text, item, index)
                },
                {
                    title: 'Total Price',
                    dataIndex: 'total_price',
                    key: 'total_price',
                    align: 'center',
                    width: '10%',
                    className: "original-style-input-table",
                    render: (text, item, index) => <span>{Intl.NumberFormat('th-TH', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item.product_price * item.quantity)}</span> 
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    align: 'center',
                    width: '5%',
                    className: "original-style-input-table",
                    render: (text, item, index) => this.renderActionColumn(text, item, index)
                },
            ]
        }
    }




    renderProductColumn(text, item, index) {
        return (
            <Select
                showSearch
                optionFilterProp={'title'}
                size="large"
                value={item.product_id}
                onChange={(value) => this.updateProductOrderListProduct(index, item, value)}
            >
                {this.state.productList.map((item, index) => <Option key={index} value={item.id} title={item.name} >{item.name}</Option>)}
            </Select>
        )
    }

    renderProductSkuColumn(text, item, index) {
        const { folderName } = this.props
        const product = this.props.productOrderList[index]
        let productId = product.product_id
        const filteredSkuList = this.state.productSkuList.filter(
            (skuItem) => skuItem.product_id === productId
        );
        return (
            <Select
                size="large"
                value={item.product_sku_id}
                onChange={(value) => this.updateProductSkuOrderListProduct(index, item, value)}
            >
                {filteredSkuList.map((item, index) => 
                <Option key={index} value={item.product_sku_id}>
                    <div style={{display:"flex",justifyContent:"flex-start",alignItems: "center", gap: 3}} >
                        {
                            item.image ? 
                            <img
                                id="target"
                                style={{ width: "auto", height: 30 }}
                                src={`${urlUpload}/${folderName}/${item.image}`}
                                onError={(image) => image.target.src = defaultSquareSrc}
                            />
                            :
                            null
                        }
                        
                        <span className='product-sku-name' >{item.attr_1}</span>
                        {item.external_product_sku_id ?<span className='product-sku-external-id'>{item.external_product_sku_id}</span>: null}
                        {/* //TODO: ถ้ามีมีค่าอะไรที่สามารนำมาโชว์เป็น sku name ได้เลย ใช้ product name */}
                        {!item.image && !item.attr_1 && item.external_product_sku_id ? <span className='product-sku-name' >{product.product_name}</span> : null}
                    </div>
                    
                </Option>)
                }
            </Select>
        )
    }



    renderQuantityColumn(text, item, index) {
        return (
            <InputNumber
                value={item.quantity}
                onChange={(value) => this.updateProductOrderListQuantity(index, value)}
            />
        )
    }

    renderActionColumn(text, item, index) {
        let disabled = false
        return (
            <div style={{
                margin: 5,
                width: 30, height: 30, borderRadius: "50%",
                backgroundColor: '#EAEAEA',
                cursor: !disabled ? 'pointer' : 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
                onClick={() => !disabled ? this.deleteProductOrderList(index) : null}
            >
                <Icon type="close" style={{ fontSize: 16, color: !disabled ? 'red' : '#fff' }} />
            </div>
        )
    }

    updateProductOrderListProduct(index, record, value) {
        const { productList } = this.state
        let indexProduct = productList.findIndex(item => item.id === value)
        if (indexProduct !== -1) {
            let productOrderList = this.props.productOrderList.map(item => Object.assign({}, item))
            productOrderList[index].product_id = productList[indexProduct].id
            productOrderList[index].product_sku_id = null
            productOrderList[index].product_price = 0
            // productOrderList[index].product_price = productList[indexProduct].price * record.quantity
            this.props.updateProductOrderList(productOrderList)
        }
    }

    updateProductSkuOrderListProduct(index, record, value) {
        const { productList } = this.state
        const indexSku = this.state.productSkuList.findIndex(item => item.product_sku_id === value);
        const productSku = this.state.productSkuList[indexSku]
        let productOrderList = this.props.productOrderList.map(item => Object.assign({}, item))
        productOrderList[index].product_sku_id = productSku.product_sku_id
        productOrderList[index].product_price = productSku.price_for_sale 

        productOrderList[index].product_weight = productSku.weight 
        productOrderList[index].product_width = productSku.width 
        productOrderList[index].product_height = productSku.height 
        productOrderList[index].product_length = productSku["length"] 
        productOrderList[index].dimension = productSku.width + productSku["length"] + productSku.height
        // * record.quantity
        this.props.updateProductOrderList(productOrderList)
    }

    updateProductOrderListQuantity(index, value) {
        let productOrderList = this.props.productOrderList.map(item => Object.assign({}, item))
        productOrderList[index].quantity = value
        this.props.updateProductOrderList(productOrderList)
    }

    deleteProductOrderList(index) {
        let productOrderList = this.props.productOrderList.filter((item, i) => i !== index)
        this.props.updateProductOrderList(productOrderList)
    }

    addProductOrderList() {
        let productOrderList = this.props.productOrderList.map(item => Object.assign({}, item))
        productOrderList.push({
            id: null,
            product_id: null,
            product_sku_id: null,
            product_price: 0,
            quantity: 1,
        })
        this.props.updateProductOrderList(productOrderList)
    }

    getProductList() {
        let url = '/api/product';
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ productList: responses.dataList })
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    getProductSkuList() {
        let url = '/api/product-sku';
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ productSkuList: responses.dataList })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getProductList()
        this.getProductSkuList()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.userCustomerId !== prevProps.userCustomerId && this.props.userCustomerId) {
            this.getUserCustomerAddressList()
        }
    }

    onChangeShippingAddressDetail(value) {
        const { userCustomerAddressList } = this.state
        let index = userCustomerAddressList.findIndex(item => item.id === value)
        if (index !== -1) {
            this.setState({
                shippingAddressId: userCustomerAddressList[index].id,
                shippingAddressDetail: userCustomerAddressList[index]
            })
        }
    }
    onChangeInvoiceAddressDetail(value) {
        const { userCustomerAddressList } = this.state
        let index = userCustomerAddressList.findIndex(item => item.id === value)
        if (index !== -1) {
            this.setState({
                invoiceAddressId: userCustomerAddressList[index].id,
                invoiceAddressDetail: userCustomerAddressList[index]
            })
        }
    }


    render() {
        const { columns } = this.state
        const { productOrderList } = this.props
        
        return (
            <div className='order-product-container-wrapper'> 
                <Row className='action-button-wrapper' gutter={24} type="flex" justify='space-between' style={{ padding: 10 }}>
                    <p className='title-header'>{'Product'}</p>
                    <Button className='create-button' onClick={() => this.addProductOrderList()}><Icon type="plus" />{'Add'}</Button>
                </Row>
                <Table
                    tableLayout='fixed'
                    dataSource={productOrderList}
                    columns={columns}
                    pagination={false}
                />
            </div>
        );
    }
}



const mapStateToProps = (state) => ({
    folderName: state.login.folderName,
})

export default connect(mapStateToProps)(OrderProduct);