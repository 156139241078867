import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Select, Icon, notification } from 'antd';
import { get, put, post, ppost } from '../../../utils';
import ImageUpload from '../../../containers/ImageUpload';
import NotificationManualModal from '../../Notification/NotificationManualSend/NotificationManualModal' 
import './style.scss'
notification.config({
    placement: 'topRight',
    duration: 1,
});
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};
const FormItem = Form.Item; 
const { TextArea } = Input;
const Option = Select.Option;

class Article extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            name: '',
            publicity: 0,
            description: '',
            publicityList: [
                { value: 0, label: 'Off' },
                { value: 1, label: 'On' },
            ],
            categoryArticleId: null,
            categoryArticleList: [],
            languageList: [],

            imageCoverList: [],

            articleId: null,
            articleLanguageList: this.props.languageList.map(item => ({
                name: '',
                imageList: [],
                language_id: item.id,
            })),
        }
    }


    getCategoryArticleList() {
        let url = '/api/crm/administrator/category/article/raw';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                let categoryArticleList = response
                if (!this.props.editId) {
                    categoryArticleList = response.filter(item => !item.is_delete)
                }
                this.setState({ categoryArticleList: categoryArticleList })
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    // getLanguageList() {
    //     let url = '/api/crm/administrator/language/raw';
    //     return get(url)
    //         .then(responses => {
    //             if (responses.status >= 400) {
    //                 throw new Error("Bad response from server");
    //             }
    //             let detailList = []
    //             responses.map(item => {
    //                 detailList.push({ name: '', language_id: item.id, short_description: '',description: '', imageList: [] })
    //             })
    //             this.setState({ 
    //                 languageList: responses,
    //                 articleLanguageList:detailList 
    //             })
    //             return responses
    //         })
    //         .catch(err => {
    //             console.log(err.message)
    //         })
    // }
    createImageObject(imageName){
        const { folderName } = this.props
        if (!imageName) return [];
        return [{
            view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${imageName}`,
            file: null,
            name: imageName
        }];
    }


    getArticleDetail(id) {
        const { folderName, languageList } = this.props
        let url = '/api/article/' + id;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                // let imageCoverList = []
                // if (response.image) {
                //     imageCoverList = [{
                //         view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${response.image}`,
                //         file: null,
                //         name: response.image
                //     }]
                // }

                languageList.map((item, indexLanguageList) => {
                    let index = response.article_language_list.findIndex(itemData => itemData.language_id == item.id)
                    if(index == -1){
                        response.article_language_list.push({ 
                            name: '',
                            image: '',
                            language_id: item.id,
                            short_description: '',
                            description: '',
                            imageList: [] 
                        })
                    }else{
                    const imageList = this.createImageObject(response.article_language_list[index].image);
                    response.article_language_list[indexLanguageList].imageList = imageList
                    }
                })
                this.setState({
                    articleId: response.id,
                    name: response.name,
                    publicity: response.publicity,
                    description: response.description,
                    categoryArticleId: response.category_article_id,
                    // imageCoverList: imageCoverList,
                    articleLanguageList: response.article_language_list
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    async componentDidMount() {
        const { editId } = this.props
        this.getCategoryArticleList()
        // await this.getLanguageList()
        if (editId) {
           await this.getArticleDetail(editId)
        }
    }

    async uploadImageToServer(formData) {
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    validateArticleLanguageList() {
        const { articleLanguageList } = this.state
        let result = {
            success: true,
            message: 'System notification',
            description: ''
        }
        if(result.success){
            if(articleLanguageList.every(article => !article.language_id)){
                result.success = false
                result.description = `Please enter "Language".`
                return
            }
            if(articleLanguageList.every(article => !article.name)){
                result.success = false
                result.description = `Please enter "Name".`
                return
            }
            if(articleLanguageList.every(article => !article.description)){
                result.success = false
                result.description = `Please enter "Description".`
                return
            }
        }

        // this.state.articleLanguageList.map((item, index) => {
        //     if (result.success) {
        //         if (!item.language_id || item.language_id == '') {
        //             result.success = false
        //             result.description = `Please enter "Language" in line ID 0000${index + 1} .`
        //             return
        //         }
        //         if (!item.name || item.name == '') {
        //             result.success = false
        //             result.description = `Please enter "Name" in line ID 0000${index + 1} .`
        //             return
        //         }
        //         if (!item.description || item.description == '') {
        //             result.success = false
        //             result.description = `Please enter "Description" in line ID 0000${index + 1} .`
        //             return
        //         }
        //     }
        // })
        return result
    }

    async updateArticle(type) {
        const { publicity, description, categoryArticleId, imageCoverList, articleLanguageList } = this.state

        if (!categoryArticleId || categoryArticleId == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please select "Article Category".')
            return
        }
        let validateArticleLanguageListResult = this.validateArticleLanguageList()
        if (!validateArticleLanguageListResult.success) {
            openNotificationWithIcon('warning', 'System notification', validateArticleLanguageListResult.description)
            return
        }
        let data = {
            publicity: publicity,
            description: description,
            category_article_id: categoryArticleId,
            user: this.props.administratorId,
            image: null,
        }

        let articleImageUploaded = await Promise.all(articleLanguageList.map(async (languageList, index) => {
              let uploadFileList = languageList.imageList.filter(item => item.file);
              if (uploadFileList.length) {
                let formData = new FormData();
                uploadFileList.forEach((item, idx) => formData.append("image" + idx, item.file));
                let fileNameList = await this.uploadImageToServer(formData);
                if (fileNameList.length) {
                  languageList.image = fileNameList[0];
                }
              } else if (languageList.imageList.length) {
                languageList.image = languageList.imageList[0].name;
              }
              languageList.imageList = []
              return languageList;
        }));
        
        data.article_language_list = JSON.stringify(articleImageUploaded)
       
        if (this.props.editId) {
            let url = '/api/article/' + this.props.editId
            put(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    if (type == 'send') {
                        this.setState({ visible: true })
                    } else {
                        this.props.backpage()
                    }
                })
                .catch(err => {
                    console.log(err.message)
                })
        } else {
            let url = '/api/article';
            post(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    if (type == 'send') {
                        this.setState({ visible: true })
                        this.getArticleDetail(response.articleId)
                    } else {
                        this.props.backpage()
                    }
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }


    onClickOk() {
        this.updateArticle('save')
    }
    onClickCancel() {
        this.setState({
            name: '',
            publicity: 0,
            description: '',
            category_article_id: null,
        })
        this.props.backpage()
    }
    onClickSaveAndSend() {
        this.updateArticle('send')
    }

    onCloseNotificationManual() {
        this.setState({ visible: false })
        this.props.backpage()
    }

    updateArticleLanguage(index, name, value) {
        let articleLanguageList = this.state.articleLanguageList.map(item => Object.assign({}, item))
        articleLanguageList[index][name] = value
        this.setState({ articleLanguageList: articleLanguageList })
    }

    // addArticleLanguage() {
    //     let articleLanguageList = this.state.articleLanguageList.map(item => Object.assign({}, item))
    //     articleLanguageList.push({
    //         language_id: null,
    //         name: '',
    //         short_description: '',
    //         description: ''
    //     })
    //     this.setState({ articleLanguageList: articleLanguageList })
    // }

    renderArticleLanguageList() {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (
            <div>
                {/* <Row gutter={24} type="flex" style={{ justifyContent: "flex-end", marginBottom: 10 }} >
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                        <Button style={{ width: '100%' }} onClick={() => this.addArticleLanguage()}>{'+ Add'}</Button>
                    </Col>
                </Row> */}
                {this.state.articleLanguageList.map((item, index) => {
                    return (
                        <Card style={{ marginBottom: 10 }}>
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                                    <FormItem {...formItemLayout} label={'Line ID'}>
                                        <p style={{ margin: 0, fontSize: 14, padding: 3 }}>{`0000${index + 1}`}</p>
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                    <FormItem {...formItemLayout} label={'Language'} required={true}>
                                        <Select
                                            allowClear={true}
                                            value={item.language_id}
                                            onChange={(value) => this.updateArticleLanguage(index, 'language_id', value)}
                                        >
                                            {this.props.languageList.length ?
                                                this.props.languageList.map((item, index) => <Option key={index} value={item.id} disabled={this.state.articleLanguageList.map(r => r.language_id).includes(item.id)} >{item.name}</Option>)
                                                : null}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={10} lg={10} xl={10} >
                                    <FormItem {...formItemLayout} label={'Name'} required={true}>
                                        <Input
                                            value={item.name}
                                            onChange={(event) => this.updateArticleLanguage(index, 'name', event.target.value)}
                                        />
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                    <FormItem {...formItemLayout} label={'Image'} required={true}>
                                        <ImageUpload
                                            label={'Upload image cover'}
                                            limit={1}
                                            dataSource={item.imageList}
                                            updateDataSource={(dataSource) => this.updateArticleLanguage(index, 'imageList', dataSource)}
                                            // updateDataSource={(dataSource) => this.setState({ imageCoverList: dataSource })}
                                        />
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                    <FormItem {...formItemLayout} label={'Description'}>
                                        <TextArea
                                            autosize={{ minRows: 10 }}
                                            value={item.description}
                                            onChange={(event) => this.updateArticleLanguage(index, 'description', event.target.value)}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Card>
                    )
                })}
            </div>
        )
    }
    render() {
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (
            <div className='article-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <div className="header-card" >
                    <Col sm={24} md={12} lg={12} style={{ textAlign: 'left', marginBottom: 10, curser: 'pointer' }} onClick={() => this.props.backpage()} >
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{this.props.editId ? 'Edit  Article' : 'Create Article'}</span>
                    </Col>
                    <Col sm={24} md={12} lg={12} style={{ textAlign: 'right', marginBottom: 10 }}>
                        <Button
                            style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{
                                marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)'
                            }}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                        <Button
                            style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(55, 168, 0)', color: '#ffffff', border: '1px solid rgb(55, 168, 0)' }}
                            onClick={() => this.onClickSaveAndSend()}
                        >
                            {'Save & Send'}
                        </Button>
                    </Col>
                </div>
                <Card className='cardstyle' >
                    {/* <Meta title="Article" /> */}
                    <Form>
                        {/* <Row gutter={24}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <Row>
                                    <Col sm={24} md={24} lg={24} style={{ flexDirection: 'row' }}>
                                        <FormItem {...formItemLayout} label={'Image'}>
                                            <ImageUpload
                                                label={'Upload image cover'}
                                                limit={1}
                                                dataSource={this.state.imageCoverList}
                                                updateDataSource={(dataSource) => this.setState({ imageCoverList: dataSource })}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row> */}
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Publicity'}>
                                    <Select
                                        value={this.state.publicity}
                                        onChange={(value) => this.setState({ publicity: value })}
                                    >
                                        {this.state.publicityList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Article Category'} required={true}>
                                    <Select
                                        value={this.state.categoryArticleId}
                                        onChange={(value) => this.setState({ categoryArticleId: value })}
                                    >
                                        {this.state.categoryArticleList.length ? this.state.categoryArticleList.map((item, index) => <Option key={index} value={item.id} disabled={item.is_delete}>{item.name}</Option>) : null}
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                        {this.renderArticleLanguageList()}
                    </Form>
                </Card>
                <NotificationManualModal
                    visible={this.state.visible}
                    notificationType={'article'}
                    articleId={this.state.articleId}
                    onClose={() => this.onCloseNotificationManual()}
                />
            </div>
        );
    }
}

Article.propTypes = {
    editId: PropTypes.number,
    backpage: PropTypes.func,
}

Article.defaultProps = {
    editId: null,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    folderName: state.login.folderName,
    languageList: state.customerLanguage.languageList,
})

export default connect(mapStateToProps, null)(Article);

