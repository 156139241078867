import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Icon, Select, notification, InputNumber } from 'antd';
import { cloneDeep } from "lodash" 
import { get, put, post } from '../../../utils';

import OrderAddress from './OrderAddress';
import OrderProduct from './OrderProduct';
import './style.scss'

const FormItem = Form.Item;
const { Meta } = Card;
const Option = Select.Option;
const { TextArea } = Input;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class SalesOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userCustomerId: null,
            userCustomerName: '',
            orderNumber: null,

            paymentStatusList: [
                { value: 'pending-payment', label: 'Pending Payment' },
                { value: 'pending-approve', label: 'Pending Approve' },
                { value: 'approve', label: 'Approve' },
                { value: 'reject', label: 'Reject' },
                { value: 'cancel', label: 'Cancel' },
            ],
            deliveryStatusList: [
                { value: 'pending', label: 'Pending' },
                { value: 'sent', label: 'Sent' },
                { value: 'cancel', label: 'Cancel' },
            ],

            customerList: [],
            paymentMethodList: [],
            deliveryMethodList: [],

            bankTransferList: [],
            paymentStatus: 'pending-payment',
            deliveryStatus: 'pending',

            bankTransferPaymentMethodId: null,

            paymentMethodId: null,
            paymentMethodBankTransferId: null,

            deliveryAddressId: null,
            invoiceAddressId: null,
            invoiceAddressDetail: null,
            deliveryAddressDetail: null,

            // deliveryPriceList: [],

            productOrderList: [],
            totalProductWeight: 0,
            deliveryPrice: null,
            price: 0,
            discountPrice: 0,
            totalPrice: 0,
            deliveryPriceErrorMessge: "",
            isCalculateDeliveryPrice: 1,
            isEditDeliveryPrice: 0,
            remarks: ""
        }
    }



    getUserCustomerList() {
        let url = '/api/user/customer';
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ customerList: responses })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getPaymentMethodList() {
        let url = '/api/payment/method';
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                let bankTransferPaymentMethodId = null
                let index = responses.findIndex(item => item.payment_method_type === 'bank_transfer')
                if (index != -1) {
                    bankTransferPaymentMethodId = responses[index].id
                    this.getPaymentBankTransferList(bankTransferPaymentMethodId)
                }
                this.setState({
                    paymentMethodList: responses,
                    bankTransferPaymentMethodId: bankTransferPaymentMethodId
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }


    getPaymentBankTransferList(paymentMethodId) {
        let url = '/api/administrator/payment/method/' + paymentMethodId + '/banktransfer';
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ bankTransferList: responses })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getDeliveryMethodList({ totalWeight, totalWidth, totalHeight, totalLength, totalDimension, maxDimension }, customerAddress, totalPrice) {
        let url = `/api/delivery/method?isDelete=0&dimension=${totalDimension}&weight=${totalWeight}&width=${totalWidth}&length=${totalLength}&height=${totalHeight}&order_price=${totalPrice}&max_dimension=${maxDimension}`;
        if(customerAddress){
            url += `&post_code=${customerAddress.postCode}&province_id=${customerAddress.provinceId}&district_id=${customerAddress.districtId}&sub_district_id=${customerAddress.subDistrictId}`;
        }

        return get(url)
            .then(responses => {
                return responses
                // if (responses.status >= 400) {
                //     throw new Error("Bad response from server");
                // }
                // this.setState({ deliveryMethodList: responses })
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    // getDeliveryMethodDetail(deliveryMethodId) {
    //     let url = '/api/crm/administrator/delivery/method/' + deliveryMethodId;
    //     get(url)
    //         .then(response => {
    //             if (response.status >= 400) {
    //                 throw new Error("Bad response from server");
    //             }
    //             console.log('response.priceList :>> ', response.priceList);
    //             this.setState({ deliveryPriceList: response.priceList }, () => this.onChangeProductOrderList(this.state.productOrderList, deliveryMethodId))
    //         })
    //         .catch(err => {
    //             console.log(err.message)
    //         })
    // }

    getSalesOrder() {
        const { editId } = this.props
        let url = '/api/crm/administrator/sales/order/' + editId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                console.log('response', response)
                let invoiceAddressDetail = response.invoiceAddressList.length ? response.invoiceAddressList[0] : null
                let deliveryAddressDetail = response.deliveryAddressList.length ? response.deliveryAddressList[0] : null
                let deliveryMethodId = response.delivery_method_id
                this.setState({
                    orderNumber: response.order_number,
                    userCustomerId: response.user_customer_id,
                    userCustomerName: response.user_customer_name,
                    paymentMethodId: response.payment_method_id,
                    deliveryMethodId: deliveryMethodId,
                    productOrderList: response.productList,
                    paymentMethodBankTransferId: response.payment_method_bank_transfer_id,
                    deliveryAddressId: deliveryAddressDetail && deliveryAddressDetail.user_customer_address_id ? deliveryAddressDetail.user_customer_address_id : null,
                    invoiceAddressId: invoiceAddressDetail && invoiceAddressDetail.user_customer_address_id ? invoiceAddressDetail.user_customer_address_id : null,
                    invoiceAddressDetail: invoiceAddressDetail,
                    deliveryAddressDetail: deliveryAddressDetail,

                    deliveryStatus: response.delivery_status,
                    deliveryPrice: response.delivery_price,
                    price: response.price,
                    discountPrice: response.discount_price,
                    totalPrice: response.total_price,
                    isCalculateDeliveryPrice: response.is_calculate_delivery_price,
                    remarks: response.remarks,
                })

                // this.getDeliveryMethodDetail(deliveryMethodId)
                this.onChangeProductOrderList(response.productList, deliveryMethodId, deliveryAddressDetail, true)
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    componentDidMount() {
        this.getInitData()
    }

    async getInitData(){
        try {
            this.getUserCustomerList()
            this.getPaymentMethodList()
            const deliveryMethodList = await this.getDeliveryMethodList({ totalWeight: 0, totalWidth: 0, totalHeight: 0, totalLength: 0, totalDimension: 0, maxDimension: 0 }, undefined, 0)
            this.setState({ deliveryMethodList: deliveryMethodList })
            if (this.props.editId) {
                this.getSalesOrder()
            }
        } catch (error) {
            console.log(error)
        }
    }

    updateSalesOrder() {
        const {
            productOrderList, deliveryAddressId, invoiceAddressId, userCustomerId,
            paymentMethodId, paymentMethodBankTransferId, paymentStatus,
            deliveryMethodId, deliveryStatus,
            deliveryPrice, price, discountPrice, 
            isCalculateDeliveryPrice, isEditDeliveryPrice,
            remarks
            // totalPrice
        } = this.state
        const hasInvalidSku = productOrderList.some(item => !item.product_sku_id);
        const hasInvalidQuantity = productOrderList.some(item => item.quantity <= 0);
        if (!userCustomerId || userCustomerId == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Customer".')
            return
        }

        if (!deliveryAddressId || deliveryAddressId == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Delivery Address".')
            return
        }

        // if (!invoiceAddressId || invoiceAddressId == '') {
        //     openNotificationWithIcon('warning', 'System notification', 'Please enter "Invoice Address".')
        //     return
        // }

        if (!paymentMethodId || paymentMethodId == '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Payment Method".')
            return
        }

        if (this.state.bankTransferPaymentMethodId === paymentMethodId && (!paymentMethodBankTransferId || paymentMethodBankTransferId == '')) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Bank Transfer".')
            return
        }

        if(productOrderList.length == 0) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Product".')
            return
        }

        if (hasInvalidSku) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Product Sku".')
            return
        }
        if (hasInvalidQuantity) {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "quantity".')
            return
        }

        console.log('deliveryPrice', deliveryPrice)
        console.log('isNaN deliveryPrice', isNaN(deliveryPrice))
        console.log('this.state.isCalculateDeliveryPrice', this.state.isCalculateDeliveryPrice)
        if((!deliveryPrice && deliveryPrice !== 0) && !this.state.isCalculateDeliveryPrice){
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Delivery Price".')
            return
        }

        let url = '/api/crm/administrator/sales/order';
        let update = post
        if (this.props.editId) {
            url = '/api/crm/administrator/sales/order/' + this.props.editId
            update = put
        }


        const totalPriceBeforeDiscount = price
        const discout = discountPrice > totalPriceBeforeDiscount ? totalPriceBeforeDiscount : discountPrice
        let totalPriceAfterDiscout = totalPriceBeforeDiscount - discout
        let totalPrice = totalPriceAfterDiscout + deliveryPrice

        // const totalPriceBeforeDiscount = price + deliveryPrice
        // const discout = discountPrice > totalPriceBeforeDiscount ? totalPriceBeforeDiscount : discountPrice
        // let totalPrice = totalPriceBeforeDiscount - discout
        
        let productList = productOrderList.map(item => Object.assign({}, { product_id: item.product_id, product_sku_id: item.product_sku_id, price: item.product_price, quantity: item.quantity }))
        let data = {
            product_list: JSON.stringify(productList),
            delivery_user_customer_address_id: deliveryAddressId,
            invoice_user_customer_address_id: invoiceAddressId,

            payment_method_id: paymentMethodId,
            payment_status: paymentStatus,
            payment_method_bank_transfer_id: paymentMethodBankTransferId,

            delivery_method_id: deliveryMethodId,
            delivery_status: deliveryStatus,

            user_customer_id: userCustomerId,

            delivery_price: deliveryPrice,
            price: price,
            discount_price: discout,
            total_price: totalPrice,
            user: this.props.administratorId,

            is_calculate_delivery_price: isCalculateDeliveryPrice,
            is_edit_delivery_price: isEditDeliveryPrice,

            remarks: remarks
        }
        update(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.props.backpage()
            })
            .catch(err => {
                console.log(err.message)
                console.log('err :>> ', err.response);
                if(err.response){
                    switch(err.response.code){
                        case "NOT_FOUND_PRODUCT":
                            openNotificationWithIcon('warning', 'System notification', 'Please enter "Product".')
                            break;
                    }

                    return
                }
                openNotificationWithIcon('warning', 'System notification', 'ไม่สามารถบันทึกข้อมูลได้ กรุณลองใหม่อีกครั้ง')
                return
            })
    }

    onClickOk() {
        this.updateSalesOrder()
    }
    onClickCancel() {
        this.setState({
            name: '',
        })
        this.props.backpage()
    }

    onChangeUserCustomer(value) {
        const { customerList } = this.state
        let index = customerList.findIndex(item => item.id === value)
        if (index !== -1) {
            this.setState({
                userCustomerId: value,
                userCustomerName: customerList[index].name
            })
        }

    }


    // calculateDeliveryPrice(totalProductWeight) {
    //     const { deliveryPriceList } = this.state
    //     let deliveryPrice = 0
    //     for (var index = 0; index < deliveryPriceList.length; index++) {
    //         let item = deliveryPriceList[index]
    //         if (totalProductWeight <= item.weight) {
    //             deliveryPrice = item.price
    //             break
    //         }
    //         if (index === deliveryPriceList.length - 1) {
    //             deliveryPrice = item.price
    //             break
    //         }
    //     }
    //     return deliveryPrice
    // }

    async onChangeProductOrderList(productOrderList, deliveryMethodId, deliveryAddressDetail, isInitGet = false) {
        const { discountPrice, isEditDeliveryPrice, 
            // deliveryPrice: tempDeliveryPrice, 
            // isCalculateDeliveryPrice 
        } = this.state
        let tempProductOrderList = cloneDeep(productOrderList)
        const idxEmpty = tempProductOrderList.findIndex(item => !item.product_sku_id)
        if(idxEmpty !== -1 || !tempProductOrderList.length) {
            // TODO: หา line ที่ไม่มีค่า product_sku_id ถ้ามี ยังไม่ต้องคำนวนค่าส่ง
            this.setState({
                productOrderList: tempProductOrderList
            })
            return
        }

        let totalProductWeight = tempProductOrderList.reduce((total, item) => total + (item.product_weight * item.quantity), 0)
        
        let productCalDelivery = tempProductOrderList.reduce((obj, item) => {
            // const dimension = item.product_width + item.product_height + item.product_length
            obj.totalWeight += item.product_weight * item.quantity
            obj.totalWidth += item.product_width * item.quantity
            obj.totalHeight += item.product_height * item.quantity
            obj.totalLength += item.product_length * item.quantity
            obj.totalDimension += item.dimension * item.quantity

            if (obj.maxDimension < item.dimension) {
                // TODO: ใช้สำหรับ codex_delivery_method calculation_method = "weight_and_max_dimension" จะใช้ค่า dimension มากสุดของสินค้าในการคิด โดยใช้ 1 qty 
                obj.maxDimension = item.dimension
            }

            return obj
        }, {
            totalWeight: 0,
            totalWidth: 0,
            totalHeight: 0,
            totalLength: 0,
            totalDimension: 0,
            maxDimension: 0,
        })

        // console.log('totalProductWeight :>> ', totalProductWeight);
        let price = tempProductOrderList.reduce((total, item) => total + (item.product_price * item.quantity), 0)
        
        let tempCustomerAddress = undefined
        if(deliveryAddressDetail){
            tempCustomerAddress = {
                postCode: deliveryAddressDetail.post_code,
                provinceId: deliveryAddressDetail.province_id,
                districtId: deliveryAddressDetail.district_id,
                subDistrictId: deliveryAddressDetail.sub_district_id,
            }
        }
        
        const totalPriceBeforeDiscount = price
        const discout = discountPrice > totalPriceBeforeDiscount ? totalPriceBeforeDiscount : discountPrice
        let totalPriceAfterDiscout = totalPriceBeforeDiscount - discout

        const deliveryMethodList= await this.getDeliveryMethodList(productCalDelivery, tempCustomerAddress, totalPriceAfterDiscout)
        
        const deliveryMethodObj = deliveryMethodList.find(item => item.id === deliveryMethodId)

        if(!deliveryMethodObj){
            // openNotificationWithIcon('warning', 'System notification', 'ช่องทางการจัดส่งนี้ไม่สามารถใช้ร่วมกับคำสั่งซื้อของคุณได้ กรุณาเลือกช่องทางการจัดส่งใหม่')
            return 
        }
        let deliveryPrice = deliveryMethodObj.delivery_method_price_id ? deliveryMethodObj.delivery_price : null
        const isCalculateDeliveryPrice = deliveryMethodObj.delivery_method_price_id ? 1 : 0
        console.log('testestestsetdeliveryPrice', deliveryPrice)

        let totalPrice = totalPriceAfterDiscout + deliveryPrice
        console.log('isCalculateDeliveryPrice', isCalculateDeliveryPrice)
        
        this.setState(prev =>({
            totalProductWeight: totalProductWeight,
            deliveryMethodList, // use
            ...isInitGet ? {} : {
                price: price,
                deliveryPrice: !prev.isCalculateDeliveryPrice && !isCalculateDeliveryPrice ? isEditDeliveryPrice ? prev.deliveryPrice : null : deliveryPrice,
                totalPrice: !prev.isCalculateDeliveryPrice && !isCalculateDeliveryPrice ? totalPriceAfterDiscout + prev.deliveryPrice : totalPrice,
                productOrderList: tempProductOrderList,
                isCalculateDeliveryPrice,
            } 
        }))


       
    }

    onChangeDeliveryPrice(deliveryPrice){
        console.log('onChangeDeliveryPrice deliveryPrice', deliveryPrice)
        const { price, discountPrice } = this.state

        const totalPriceBeforeDiscount = price
        const discout = discountPrice > totalPriceBeforeDiscount ? totalPriceBeforeDiscount : discountPrice
        let totalPriceAfterDiscout = totalPriceBeforeDiscount - discout
        let tempDeliveryPrice = isNaN(deliveryPrice) ? 0 : deliveryPrice
        let totalPrice = totalPriceAfterDiscout + tempDeliveryPrice

        this.setState({ 
            deliveryPrice: deliveryPrice,
            isEditDeliveryPrice: 1,
            totalPrice: totalPrice
        })

    }

    onChangeDeliveryMethod(value) {
        const { productOrderList, deliveryAddressDetail } = this.state
        this.setState({ deliveryMethodId: value })
        // this.getDeliveryMethodDetail(value)
        this.onChangeProductOrderList(productOrderList, value, deliveryAddressDetail)
    }


    customerListToEnum() {
        const { customerList } = this.state
        let registerCustomerList = customerList.filter(item => item.is_guest === 0)
        let result = registerCustomerList.map((item) => {
            return <Option key={item.id} value={item.id} title={item.email}>{item.email ? item.email : item.name}</Option>
        })
        return result
    }

    onChangeDeliveryAddress(deliveryAddressDetail) {
        const { productOrderList, deliveryMethodId } = this.state
        this.setState({ deliveryAddressDetail: deliveryAddressDetail })
        this.onChangeProductOrderList(productOrderList, deliveryMethodId, deliveryAddressDetail)
    }

    render() {       
        const { discountPrice, price, deliveryPrice = 0, deliveryMethodId, deliveryAddressDetail, isCalculateDeliveryPrice } = this.state
        const totalPriceBeforeDiscount = price + deliveryPrice
        const discout = discountPrice > totalPriceBeforeDiscount ? totalPriceBeforeDiscount : discountPrice

        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayoutTextEditor = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayoutSummary = {
            labelCol: {
                xs: { span: 12 },
                sm: { span: 12 },
            },
            wrapperCol: {
                xs: { span: 12 },
                sm: { span: 12 },
            },
        };
        
        let styleButtonSave = {
            backgroundColor: 'rgb(72, 197, 235)', 
            color: '#ffffff', 
            border: '1px solid rgb(72, 197, 235)'
        }

        console.log('deliveryPrice', deliveryPrice)
        return (
            <div className='categoryarticle-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <Row className="header-card" >
                    <Col span={12} style={{ textAlign: 'left', cursor: 'pointer' }} onClick={() => this.props.backpage()}>
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{this.props.editId ? 'Edit Sales Order' : 'Create Sales Order'}</span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                        <Button style={{ marginLeft: 2, marginRight: 2, ...styleButtonSave }} onClick={() => this.onClickOk()} >{'Save'}</Button>
                    </Col>
                </Row>
                <Card className='cardstyle'>
                    <Form>
                        <Row gutter={24}>
                            <Col sm={24} md={24} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Number'} required={false}>
                                    <Input
                                        value={this.state.orderNumber}
                                        disabled={true}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={24} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'E-mail'} required={true}>
                                    <Select
                                        showSearch={true}
                                        optionFilterProp={'title'}
                                        value={this.state.userCustomerId}
                                        onChange={(value) => this.onChangeUserCustomer(value)}
                                    >
                                        {this.customerListToEnum()}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col sm={24} md={24} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Name'}>
                                    <Input
                                        value={this.state.userCustomerName}
                                        disabled={true}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col sm={24} md={24} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Payment Method'} required={true}>
                                    <Select
                                        value={this.state.paymentMethodId}
                                        onChange={(value) => this.setState({ paymentMethodId: value })}
                                    >
                                        {this.state.paymentMethodList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                            {this.state.bankTransferPaymentMethodId === this.state.paymentMethodId ?
                                <Col sm={24} md={24} lg={8} xl={8} >
                                    <FormItem {...formItemLayout} label={'Bank Transfer'} required={true}>
                                        <Select
                                            value={this.state.paymentMethodBankTransferId}
                                            onChange={(value) => this.setState({ paymentMethodBankTransferId: value })}
                                        >
                                            {this.state.bankTransferList.map((item, index) => <Option key={index} value={item.id}>{`${item.name}-${item.account}-${item.bank_name}`}</Option>)}
                                        </Select>
                                    </FormItem>
                                </Col>
                                : null
                            }
                            <Col sm={24} md={24} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Payment Status'} required={true}>
                                    <Select
                                        value={this.state.paymentStatus}
                                        onChange={(value) => this.setState({ paymentStatus: value })}
                                        disabled={true}
                                    >
                                        {this.state.paymentStatusList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col sm={24} md={24} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Delivery Method'} required={true}>
                                    <Select
                                        value={this.state.deliveryMethodId}
                                        onChange={(value) => this.onChangeDeliveryMethod(value)}
                                    >
                                        {this.state.deliveryMethodList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col sm={24} md={24} lg={8} xl={8} >
                                <FormItem {...formItemLayout} label={'Delivery Status'} required={true}>
                                    <Select
                                        value={this.state.deliveryStatus}
                                        onChange={(value) => this.setState({ deliveryStatus: value })}
                                        disabled={true}
                                    >
                                        {this.state.deliveryStatusList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                        <OrderAddress
                            userCustomerId={this.state.userCustomerId}

                            deliveryAddressId={this.state.deliveryAddressId}
                            invoiceAddressId={this.state.invoiceAddressId}
                            deliveryAddressDetail={this.state.deliveryAddressDetail}
                            invoiceAddressDetail={this.state.invoiceAddressDetail}

                            updateDeliveryAddressId={(deliveryAddressId) => this.setState({ deliveryAddressId: deliveryAddressId })}
                            updateInvoiceAddressId={(invoiceAddressId) => this.setState({ invoiceAddressId: invoiceAddressId })}
                            updateDeliveryAddressDetail={(deliveryAddressDetail) => this.onChangeDeliveryAddress(deliveryAddressDetail)}
                            updateInvoiceAddressDetail={(invoiceAddressDetail) => this.setState({ invoiceAddressDetail: invoiceAddressDetail })}
                        />
                        <Row>
                            <Col sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Remarks'} required={false}>
                                    <TextArea
                                        autosize={{ minRows: 3 }}
                                        value={this.state.remarks}
                                        onChange={(event) => this.setState({ remarks: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                          

                        <OrderProduct
                            productOrderList={this.state.productOrderList}
                            updateProductOrderList={(productOrderList) => this.onChangeProductOrderList(productOrderList, deliveryMethodId, deliveryAddressDetail)}
                        />
                        <div>
                            <Row gutter={24} type='flex' justify='end'>
                                <Col sm={24} md={24} lg={6} xl={6} >
                                    <FormItem {...formItemLayoutSummary} label={'Price'} style={{ margin: 0 }}>
                                        <p style={{ fontSize: 14, padding: 6, margin: 0, textAlign: 'end' }}>
                                            <span>{Intl.NumberFormat('th-TH', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(price)}</span>
                                            <span>{" THB"}</span></p>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={24} type='flex' justify='end'>
                                <Col sm={24} md={24} lg={6} xl={6} >
                                    <FormItem {...formItemLayoutSummary} label={'Delivery Price'} style={{ margin: 0, alignItems: "center", display: "flex" }}>
                                        <p style={{ fontSize: 14, padding: 6, margin: 0, textAlign: 'end', display: "flex", alignItems: "center",justifyContent: "flex-end", gap: 3 }}>
                                            
                                            {
                                                isCalculateDeliveryPrice ? 
                                                <span>{Intl.NumberFormat('th-TH', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(deliveryPrice)}</span>
                                                :
                                                <span>
                                                    <Input type='number' value={deliveryPrice} onChange={(e)=>this.onChangeDeliveryPrice(parseFloat(e.target.value))} />
                                                </span>
                                            }
                                            
                                            <span>{" THB"}</span>
                                        </p>
                                    </FormItem>
                                    {/* <span style={{ color: 'red' }}>{deliveryPriceErrorMessge}</span> */}
                                </Col>
                            </Row>
                            <Row gutter={24} type='flex' justify='end'>
                                <Col sm={24} md={24} lg={6} xl={6} >
                                    <FormItem {...formItemLayoutSummary} label={'Discount Price'} style={{ margin: 0 }}>
                                        <p style={{ fontSize: 14, padding: 6, margin: 0, textAlign: 'end' }}>
                                            <span>{Intl.NumberFormat('th-TH', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(discout)}</span>
                                            <span>{" THB"}</span></p>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={24} type='flex' justify='end'>
                                <Col sm={24} md={24} lg={6} xl={6} >
                                    <FormItem {...formItemLayoutSummary} label={'Total Price'} style={{ margin: 0 }}>
                                        <p style={{ fontSize: 14, padding: 6, margin: 0, textAlign: 'end' }}>
                                            <span>{Intl.NumberFormat('th-TH', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(this.state.totalPrice)}</span>
                                            <span>{" THB"}</span>
                                        </p>
                                    </FormItem>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Card>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
})

export default connect(mapStateToProps, null)(SalesOrder);

