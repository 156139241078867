import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Icon, Select, notification } from 'antd';
import { get, put, post } from '../../../utils';
import BankTransfer from './BankTransfer';
import PaymentGatewayInput from './PaymentGatewayInput';
import './style.scss'

const formLanguageLayout = {
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

const FormItem = Form.Item;
// const { Meta } = Card;
const Option = Select.Option;

class PaymentMethod extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentMethodId: null,
            name: '',
            paymentMethodLanguageList: [],
            paymentMethodType: null,
            bankTransferList: [],
            publishingList: [
                { value: 1, label: 'Yes' },
                { value: 0, label: 'No' },
            ],
            paymentMethodTypeList: [
                { value: 'bank_transfer', label: 'Bank Transfer' },
                { value: 'omise', label: 'Omise' },
            ],
            productionModeList: [
                { value: 1, label: 'Yes' },
                { value: 0, label: 'No' },
            ],
            application_id_one: null,
            application_id_two: null,
            application_id_three: null,
            prefixInvoice: "CODEX"
        }
    }


    getPaymentMethodDetail() {
        const { editId } = this.props
        let url = '/api/administrator/payment/method/' + editId;
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.props.languageList.map(item => {
                    let index = response.payment_method_language_list.findIndex(itemData => itemData.language_id == item.id)
                    if (index == -1) {
                        response.payment_method_language_list.push({ name: '', language_id: item.id })
                    }
                })
                this.setState({
                    paymentMethodId: response.id,
                    name: response.name,
                    paymentMethodType: response.payment_method_type,
                    bankTransferList: response.bankTransferList,
                    isPublished: response.is_published,
                    isProductionMode: response.is_production_mode,
                    prefixInvoice: response.prefix_invoice,
                    application_id_one: response.application_id_one,
                    application_id_two: response.application_id_two,
                    application_id_three: response.application_id_three,
                    paymentMethodLanguageList: response.payment_method_language_list
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    async componentDidMount() {
        let detailList = []
        this.props.languageList.map(item=>{
            detailList.push({ 
                name: '',
                language_id: item.id
             })
        })
        await this.setState({
            paymentMethodLanguageList: detailList,
        })
        if (this.props.editId) {
            this.getPaymentMethodDetail()
        }
    }


    updatePaymentMethod() {
        const { name, prefixInvoice, isProductionMode, paymentMethodLanguageList } = this.state
        let url = '/api/crm/administrator/payment/method';
        let update = post
        if (this.props.editId) {
            url = '/api/crm/administrator/payment/method/' + this.props.editId
            update = put
        }
        if(paymentMethodLanguageList.every(payment => !payment.name)){
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Name".')
            return
        }
        let data = {
            // name: name,
            bankTransferList: JSON.stringify(this.state.bankTransferList),
            user: this.props.administratorId,
            isPublished:this.state.isPublished,
            is_production_mode: isProductionMode,

            prefix_invoice: prefixInvoice,
            application_id_one: this.state.application_id_one,
            application_id_two: this.state.application_id_two,
            application_id_three: this.state.application_id_three,

            payment_method_language_list: paymentMethodLanguageList,
        }
        update(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.props.backpage()
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickOk() {
        this.updatePaymentMethod()
    }
    onClickCancel() {
        this.setState({
            name: '',
        })
        this.props.backpage()
    }

    updateNameLanguage(index, name, value) {
        let paymentMethodLanguageList = this.state.paymentMethodLanguageList.map(item => Object.assign({}, item))
        paymentMethodLanguageList[index][name] = value
        this.setState({ paymentMethodLanguageList: paymentMethodLanguageList })
    }

    render() {
        const { prefixInvoice } = this.state
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };

        return (
            <div className='paymentmethod-page-wrapper' style={{ backgroundColor: '#FFFFFF', height: '90vh' }}>
                <Row className="header-card" >
                    <Col span={12} style={{ textAlign: 'left',cursor: 'pointer' }} onClick={() => this.props.backpage()} >
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{this.props.editId ? 'Edit Payment Method' : 'Create Payment Method'}</span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickCancel()}>{'Cancel'}</Button>
                        <Button style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }} onClick={() => this.onClickOk()} >{'Save'}</Button>
                    </Col>
                </Row>
                <Card className='cardstyle'>
                    <Form>
                        <Row gutter={24} style={{ marginBottom: 20 }}>
                            {/* <Col xs={24} sm={24} md={24} lg={12} xl={12} >
                                <FormItem {...formItemLayout} label={'Name'} required={true}>
                                    <Input
                                        value={this.state.name}
                                        onChange={(event) => this.setState({ name: event.target.value })}
                                        maxLength={45}
                                    />
                                </FormItem>
                            </Col> */}
                            {this.state.paymentMethodLanguageList.map((item, index) => {
                                return (
                                    <Card style={{ marginBottom: 10 }} className='language-card'>
                                        <Row gutter={24}>
                                            <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                                                <FormItem {...formLanguageLayout} label={'Line ID'}>
                                                    <p style={{ margin: 0, fontSize: 14, padding: 3 }}>{`0000${index + 1}`}</p>
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                                <FormItem {...formLanguageLayout} label={'Language'} required={true} style={{textAlign: "start"}}>
                                                    <Select
                                                        allowClear={true}
                                                        value={item.language_id}
                                                        onChange={(value) => this.updateNameLanguage(index, 'language_id', value)}
                                                    >
                                                        {this.props.languageList.length ?
                                                            this.props.languageList.map((item, index) => 
                                                            <Option 
                                                                key={index} 
                                                                value={item.id} 
                                                                disabled={this.state.paymentMethodLanguageList.map(r => r.language_id).includes(item.id)}
                                                            >
                                                                {item.name}
                                                            </Option>)
                                                            : null}
                                                    </Select>
                                                </FormItem>
                                            </Col>
                                            <Col xs={24} sm={24} md={10} lg={10} xl={10} >
                                                <FormItem {...formLanguageLayout} label={'Name'} required={true}>
                                                    <Input
                                                        value={item.name}
                                                        onChange={(event) => this.updateNameLanguage(index, 'name', event.target.value)}
                                                    />
                                                </FormItem>
                                            </Col>
                                        </Row>
                                    </Card>
                                )
                            })}
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} >
                                <FormItem {...formItemLayout} label={'Publishing'} required={true}>
                                    <Select
                                        value={this.state.isPublished}
                                        onChange={(value) => this.setState({ isPublished: value })}
                                    >
                                        {this.state.publishingList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} >
                                <FormItem {...formItemLayout} label={'Type'} required={true}>
                                    <Select
                                        value={this.state.paymentMethodType}
                                        onChange={(value) => this.setState({ paymentMethodType: value })}
                                        disabled={true}
                                    >
                                        {this.state.paymentMethodTypeList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} >
                                <FormItem {...formItemLayout} label={'Production Mode'} required={true}>
                                    <Select
                                        value={this.state.isProductionMode}
                                        onChange={(value) => this.setState({ isProductionMode: value })}
                                    >
                                        {this.state.productionModeList.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                        {this.state.paymentMethodType === 'bank_transfer' ?
                            <BankTransfer
                                bankTransferList={this.state.bankTransferList}
                                updateBankTransferList={(value) => this.setState({ bankTransferList: value })}
                                paymentMethodId={this.state.paymentMethodId}
                            />
                            : null}
                        {this.state.paymentMethodType === 'omise' ?
                            <PaymentGatewayInput
                                application_id_one_title={'Omise Public Key'}
                                application_id_two_title={'Omise Secret Key'}
                                application_id_three_title={'Omise Public Key'}
                                prefixInvoice={prefixInvoice}
                                application_id_one={this.state.application_id_one}
                                application_id_two={this.state.application_id_two}
                                application_id_three={this.state.application_id_three}
                                updateState={(nextState) => this.setState(nextState)}
                            />
                            : null}
                        {["2c2p_credit_card", "2c2p_promptpay"].includes(this.state.paymentMethodType) ?
                            <PaymentGatewayInput
                                application_id_one_title={'Merchant ID'}
                                application_id_two_title={'Secret Key'}
                                // application_id_three_title={'Omise Public Key'}
                                prefixInvoice={prefixInvoice}
                                application_id_one={this.state.application_id_one}
                                application_id_two={this.state.application_id_two}
                                application_id_three={this.state.application_id_three}
                                updateState={(nextState) => this.setState(nextState)}
                            />
                            : null}


                    </Form>
                </Card>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    languageList: state.customerLanguage.languageList,
})

export default connect(mapStateToProps, null)(PaymentMethod);

